import React, { useEffect, useState } from 'react'
import { Space, Col, Row, Form, Input, message, Spin, Select, DatePicker, Radio, Button, Checkbox, InputNumber, notification, Divider } from 'antd';
import { useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import _ from 'lodash';
import { getTitle, getGroup_position, getEmployee_type, getPosition, getSection, getDepartment, getBusinessUnit } from "../../slice/businessunitSlice";
import dayjs from 'dayjs';
import getOne from '../../api/employee/getOne';
import EditEmployee from '../../api/employee/editEmployee';

const Cutter = {
    strokeWidth: '1px',
    stroke: 'var(--primary-main, #2196F3)',
    color: 'var(--primary-main, #2196F3)',
    textAlign: 'center',
    marginBottom: '1rem'
};
export default function EditFormEmployee(props) {
    const [form] = Form.useForm();
    const [isFetch, setIsfetch] = useState(false);
    const [employeeStatus, setEmployeestatus] = useState(true)
    const dataTitle = useSelector(getTitle);
    const dataEmployee_type = useSelector(getEmployee_type);
    const dataGroup_position = useSelector(getGroup_position);
    const dataSection = useSelector(getSection);
    const dataDepartment = useSelector(getDepartment);
    const dataBU = useSelector(getBusinessUnit);
    const dataPosition = useSelector(getPosition);
    const [api, contextHolder] = notification.useNotification();
    const { id } = useParams();
    const navigate = useNavigate()
    const formStyle = {
        maxWidth: 'none',
        padding: 24,
    };
    const config = {
        rules: [
            {
                type: 'object',
                required: true,
                message: 'Please select time!',
            },
        ],
    };
    const openNotificationWithIcon = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    };
    const Filterpositoin_ToBu = async (event) => {
        if (event === null || event === undefined) {
            return;
        }
        var name_keys = { "sectionsId": dataSection, "departmentsId": dataDepartment, "business_groupsId": dataBU };
        var position = await dataPosition.find(p => p.id === event);
        if (!position) {
            return;
        }
        Object.keys(name_keys).reduce((acc, name, index) => {
            if (acc !== null || acc !== undefined) {
                var n_t = name_keys[name].find(p => p.id === acc);
                var next_id = Object.keys(name_keys)[index + 1];
                if (next_id !== undefined && n_t !== undefined) {
                    name_keys[name] = n_t.name;
                    return n_t[next_id];
                }
                else {
                    name_keys[name] = n_t?.name ?? '-'
                    return -1;
                }
            }

        }, position.sectionsId)
        form.setFieldsValue(name_keys);
    };
    useEffect(() => {
        if (!isFetch && id) {
            fetchData(id);
        }

        return () => {
            // cleanup
        };
    }, []);
    const fetchData = (id) => {
        getOne(id, (err, res) => {
            if (err) {
                console.log(err);
            }
            if (!err && res.statusCode === 200) {
                let data = res.data;
                data['employee_id'] = data.employeeId
                data["title"] = data.title?.id
                data["gender"] = data.gender?.id
                data["identification_card"] = data.personal?.number ?? null
                if (!_.isEmpty(data.probation_date)) {
                    data["probation_date"] = dayjs(data.personal?.probation_date)
                }
                if (!_.isEmpty(data.birthday_date)) {
                    data["birthday"] = dayjs(data.birthday_date);
                }
                if (!_.isEmpty(data.personal?.expire_date)) {
                    data["expireddate"] = dayjs(data.personal.expire_date)
                }
                if (!_.isEmpty(data.personal?.issue_card)) {
                    data["issuedate"] = dayjs(data.personal.issue_card)
                }
                if (!_.isEmpty(data.entry_date)) {
                    data["entrydate"] = dayjs(data.entry_date)
                }

                data["nationality"] = data.personal?.nationality ?? null
                data["religion"] = data.personal?.religion ?? null
                data["employee_type"] = data.employment_type?.id ?? null

                data["groupposition"] = data.group_positions?.id ?? null
                data["tier"] = parseInt(data.tier_level)
                // "inactivedate"] = "-", // TODO
                data["houseno"] = data.personal?.address_detail ?? null
                data["soi"] = data.personal?.soi ?? null
                data["road"] = data.personal?.road ?? null
                data["sub_district"] = data.personal?.subdistrict ?? null
                data["district"] = data.personal?.district ?? null
                data["province"] = data.personal?.province ?? null
                data["postal_code"] = data.personal?.postal_code ?? null
                data["houseno_mailing"] = data.address?.address_detail ?? null
                data["soi_mailing"] = data.address?.soi ?? null
                data["road_mailing"] = data.address?.road ?? null
                data["sub_district_mailing"] = data.address?.subdistrict ?? null
                data["district_mailing"] = data.address?.district ?? null
                data["province_mailing"] = data.address?.province ?? null
                data["postal_code_mailing"] = data.address?.postal_code ?? null
                setEmployeestatus(data.employee_statusId === 1 ? true : false)
                form.setFieldsValue(data);
                Filterpositoin_ToBu(data.positions?.id ?? null);
                setIsfetch(true);
            } else {
                message.error(res?.message ?? 'fail')
            }
        })
    }
    const onFinish = (values) => {
        Object.keys(values).forEach(element => {
            if (values[element] === undefined || values[element] === null) {
                values[element] = '-'
            }
            values['employee_status'] = employeeStatus
        });
        EditEmployee(id, values, (err, res) => {
            if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                message.success("Edit success");
                setIsfetch(false);
                setTimeout(() => {
                    navigate('/employee')
                }, 1000);
            } else {
                openNotificationWithIcon('error', 400, err ?? 'error not known');
            }
        });

    };
    return (
        <div style={{ padding: '20px', margin: '20px 15px 15px 20px', backgroundColor: "#FFF" }}>
            {contextHolder}
            <Spin spinning={!isFetch} delay={500}>
                <Row >
                    <Col span={12} offset={6}>
                        <Form form={form} name="advanced_search" style={formStyle} layout='vertical' onFinish={(e) => onFinish(e)}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', flexWrap: 'nowrap', height: '600px', overflowY: 'scroll' }}>
                                <Divider style={Cutter}>Profile*</Divider>
                                <Row>
                                    <Col >
                                        <Form.Item
                                            label='Title'
                                            name="title"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select Title!',
                                                },
                                            ]}
                                        >
                                            <Select
                                                style={{
                                                    width: 120,
                                                }}
                                                placeholder="Title"
                                                options={dataTitle.map(t => { return { value: t.id, label: t.name } })}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Space>
                                        <Col>
                                            <Form.Item
                                                label='First Name(TH)'
                                                name={`firstname_th`}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Input something!',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Fullname [TH]" />
                                            </Form.Item>
                                        </Col>
                                        <Col >
                                            <Form.Item
                                                label='Last Name(TH)'
                                                name={`lastname_th`}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Input something!',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Lastname [TH]" />
                                            </Form.Item>
                                        </Col>
                                    </Space>
                                </Row>
                                <Row>
                                    <Space >
                                        <Col >
                                            <Form.Item
                                                label='First Name(EN)'
                                                name={`firstname_en`}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Input something!',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Fullname [EN]" />
                                            </Form.Item>
                                        </Col>
                                        <Col >
                                            <Form.Item
                                                label='Last Name(EN)'
                                                name={`lastname_en`}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Input something!',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Lastname [EN]" />
                                            </Form.Item>
                                        </Col>
                                    </Space>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Item
                                            label='Nickname'
                                            name={`nickname`}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Input something!',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Nickname" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Space>
                                        <Col>
                                            <Form.Item name="gender" label="Gender *">
                                                <Radio.Group >
                                                    <Radio value={1}>Male</Radio>
                                                    <Radio value={2}>Female</Radio>
                                                </Radio.Group>
                                            </Form.Item>

                                        </Col>
                                        <Col >
                                            <Form.Item name="birthday" label="Birth Day" {...config}>
                                                <DatePicker placeholder='Birthday Date' />
                                            </Form.Item>
                                        </Col>
                                    </Space>
                                </Row>
                                <Row>
                                    <Space>
                                        <Col>
                                            <Form.Item
                                                label='Identification Card'
                                                name={`identification_card`}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Input something!',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Identification Card" />
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Form.Item name="issuedate" {...config} label='Issue Date'>
                                                <DatePicker placeholder='Issue Date' />
                                            </Form.Item>
                                        </Col>
                                        <Col >
                                            <Form.Item name="expireddate"  {...config} label='Expired Date'>
                                                <DatePicker placeholder='Expired Date' />
                                            </Form.Item>
                                        </Col>
                                    </Space>
                                </Row>
                                <Row>
                                    <Space>
                                        <Col>
                                            <Form.Item
                                                label='Nationality'
                                                name={`nationality`}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Input something!',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Nationality" />
                                            </Form.Item>
                                        </Col>
                                        <Col >
                                            <Form.Item
                                                label='Religion'
                                                name={`religion`}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Input something!',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Religion" />
                                            </Form.Item>
                                        </Col>
                                    </Space>
                                </Row>
                                <Divider style={Cutter}>Employee Detail</Divider>
                                <Row>
                                    <Space>
                                        <Col>
                                            <Form.Item
                                                label='Email'
                                                name={`email`}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Input something!',
                                                    },
                                                ]}>
                                                <Input placeholder="Email" />
                                            </Form.Item>
                                        </Col>
                                        <Col >
                                            <Form.Item name={`phone`} label={'Phone'} >
                                                <Input placeholder="Phone Number" />
                                            </Form.Item>
                                        </Col>
                                    </Space>
                                </Row>
                                <Row>
                                    <Space>
                                        <Col>
                                            <Form.Item name={`employee_id`} label={'Employee ID'}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Input something!',
                                                    },
                                                ]}>
                                                <Input placeholder="Employee ID" />
                                            </Form.Item>
                                        </Col>
                                        <Col >
                                            <Form.Item name={`employee_type`}
                                                label='Employee Type'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Input something!',
                                                    },
                                                ]}>
                                                <Select
                                                    style={{
                                                        width: 120,
                                                    }}
                                                    placeholder="Employee Type"
                                                    options={dataEmployee_type.map(t => { return { value: t.id, label: t.name } })}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Space>
                                </Row>
                                <Row>
                                    <Space>
                                        <Col>
                                            <Form.Item name="entrydate" label={'Entry Date'} >
                                                <DatePicker placeholder='Entry Date' />
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Form.Item name="probation_date" label={'Probation Date'} >
                                                <DatePicker placeholder='Probation Date' />
                                            </Form.Item>
                                        </Col>
                                    </Space>
                                </Row>
                                <Row>
                                    <Space>
                                        <Col>
                                            <Form.Item
                                                label={'Position'}
                                                name={`positionsId`}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Input something!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    style={{
                                                        width: 150,
                                                    }}
                                                    placeholder="Position"
                                                    onChange={(e) => { Filterpositoin_ToBu(e) }}
                                                    options={dataPosition.map(t => { return { value: t.id, label: t.name } })}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col >
                                            <Form.Item name={`sectionsId`} label={'Section'}>
                                                <Input placeholder="Section" disabled />
                                            </Form.Item>
                                        </Col>
                                    </Space>
                                </Row>
                                <Row>
                                    <Space>
                                        <Col>
                                            <Form.Item name={`departmentsId`} label={'Department'}>
                                                <Input placeholder="Department" disabled />
                                            </Form.Item>
                                        </Col>
                                        <Col >
                                            <Form.Item name={`business_groupsId`} label={'Business Unit'}>
                                                <Input placeholder="Business Unit" disabled />
                                            </Form.Item>
                                        </Col>
                                    </Space>
                                </Row>
                                <Row>
                                    <Space>
                                        <Col>
                                            <Form.Item name={`groupposition`} label={'Group Position'}>
                                                <Select
                                                    style={{
                                                        width: 150,
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    placeholder="Group Position"
                                                    options={dataGroup_position.map(t => { return { value: t.id, label: t.name } })}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col >
                                            <Form.Item name={`tier`}
                                                label='Tier Lavel'
                                                rules={[
                                                    {
                                                        type: 'number',
                                                        min: 0,
                                                        max: 99,
                                                    },
                                                ]}>
                                                <InputNumber style={{ width: 100, }} placeholder="Tier Level" />
                                            </Form.Item>
                                        </Col>
                                    </Space>
                                </Row>
                                <Row>
                                    <Space align="-">
                                        <Col >
                                            <Button type={employeeStatus ? "primary" : ''}
                                                onClick={() => { setEmployeestatus(true) }}>Active</Button>
                                            <Button type={!employeeStatus ? "primary" : ''}
                                                onClick={() => { setEmployeestatus(false) }} >Inactive</Button>
                                        </Col>
                                        <Col>
                                            <Form.Item name="inactivedate" >
                                                <DatePicker placeholder='Inactive Date' />
                                            </Form.Item>
                                        </Col>
                                    </Space>
                                </Row>
                                <Divider style={Cutter}>Address Information</Divider>
                                <span style={{
                                    color: 'var(--disabled-text-placeholder, rgba(0, 0, 0, 0.30))',
                                    marginBottom: '10px'
                                }}>Home Address</span>
                                <Row>
                                    <Col span={13}>
                                        <Form.Item name={`houseno`} style={{ width: '100%' }} label='House no.'>
                                            <Input placeholder="House no." />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Space>
                                        <Col>
                                            <Form.Item name={`soi`} label='Soi'>
                                                <Input placeholder="Soi" />
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Form.Item name={`road`} label='Road'>
                                                <Input placeholder="Road" />
                                            </Form.Item>
                                        </Col>
                                    </Space>
                                </Row>

                                <Row>
                                    <Space>
                                        <Col>
                                            <Form.Item name={`sub_district`} label='Sub-district'>
                                                <Input placeholder="Sub-district" />
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Form.Item name={`district`} label='District'>
                                                <Input placeholder="District" />
                                            </Form.Item>
                                        </Col>
                                    </Space>
                                </Row>
                                <Row>
                                    <Space>
                                        <Col>
                                            <Form.Item name={`province`} label='Province'>
                                                <Input placeholder="Province" />
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Form.Item name={`postal_code`} label='Postal Code'>
                                                <Input placeholder="Postal Code" />
                                            </Form.Item>
                                        </Col>
                                    </Space>
                                </Row>
                                <Checkbox onChange={(e) => {
                                    let data_form = form.getFieldsValue();
                                    if (e.target.checked) {
                                        form.setFieldsValue({
                                            houseno_mailing: data_form.houseno,
                                            soi_mailing: data_form.soi,
                                            road_mailing: data_form.road,
                                            sub_district_mailing: data_form.sub_district,
                                            district_mailing: data_form.district,
                                            province_mailing: data_form.province,
                                            postal_code_mailing: data_form.postal_code
                                        });
                                    } else {
                                        form.setFieldsValue({
                                            houseno_mailing: '',
                                            soi_mailing: '',
                                            road_mailing: '',
                                            sub_district_mailing: '',
                                            district_mailing: '',
                                            province_mailing: '',
                                            postal_code_mailing: ''
                                        });
                                    }
                                }}>Same as above</Checkbox>
                                <span style={{ color: 'var(--disabled-text-placeholder, rgba(0, 0, 0, 0.30))', marginTop: '10px', marginBottom: '10px' }}>Mailing Address</span>
                                <Row>
                                    <Space>
                                        <Col>
                                            <Form.Item name={`houseno_mailing`} label='House no.'>
                                                <Input placeholder="House no." />
                                            </Form.Item>
                                        </Col>
                                    </Space>
                                </Row>
                                <Row>
                                    <Space>
                                        <Col>
                                            <Form.Item name={`soi_mailing`} label='Soi'>
                                                <Input placeholder="Soi" />
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Form.Item name={`road_mailing`} label='Road'>
                                                <Input placeholder="Road" />
                                            </Form.Item>
                                        </Col>
                                    </Space>
                                </Row>
                                <Row>
                                    <Space>
                                        <Col>
                                            <Form.Item name={`sub_district_mailing`} label='Sub-district'>
                                                <Input placeholder="Sub-district" />
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Form.Item name={`district_mailing`} label='District'>
                                                <Input placeholder="District" />
                                            </Form.Item>
                                        </Col>
                                    </Space>
                                </Row>
                                <Row>
                                    <Space>
                                        <Col>
                                            <Form.Item name={`province_mailing`} label='Province' rules={[

                                                { max: 20, message: 'maximum 20 characters.' },
                                            ]} >
                                                <Input placeholder="Province" />
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Form.Item name={`postal_code_mailing`} label='Postal Code' rules={[
                                                { max: 20, message: 'maximum 20 characters.' },
                                            ]}>
                                                <Input placeholder="Postal Code" />
                                            </Form.Item>
                                        </Col>
                                    </Space>
                                </Row>
                            </div>
                            <Row justify={'center'} style={{ marginTop: '2rem' }}>
                                <Col>
                                    <Button type="primary" htmlType="submit">
                                        Edit Employee
                                    </Button>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>
            </Spin>
        </div>
    )
}