import React, { useEffect, } from 'react'
import { Space, Col, Row, Form, Input, message, notification, Button, Select } from 'antd';
import { useSelector } from 'react-redux'

import addPosition from '../.././api/positions/addPosition'

import { getSection, } from "../../slice/businessunitSlice";

export default function AddFormPosition(props) {
    const [api, contextHolder] = notification.useNotification();
    const dataSection = useSelector(getSection);
    const openNotificationWithIcon = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    };
    const [form] = Form.useForm();
    const formStyle = {
        maxWidth: 'none',
        padding: 24,
    };

    useEffect(() => {
        form.setFieldsValue({ 'sectionsId': dataSection[0]?.id ?? null })
        return () => {

        };
    }, [dataSection]);

    return (
        <div style={{ padding: '20px', margin: '20px 15px 15px 20px', backgroundColor: "#FFF" }}>
            {contextHolder}
            <Row>
                <Col span={4}></Col>
                <Col span={16}>
                    <Form form={form} name="advanced_search" layout='vertical' style={formStyle} onFinish={(e) => {
                        addPosition(e, (err, res) => {
                            if (err) {
                                openNotificationWithIcon('error', 400, err ?? 'error not known');
                            }
                            if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                                form.resetFields();
                                props.handleUpdated();
                                props.onClose();
                                message.success("This is a success add Position");
                            }
                            if (res.statusCode >= 400) {
                                openNotificationWithIcon('error', res.statusCode, res.message);
                            }
                        })
                    }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', flexWrap: 'wrap' }}>
                            <Row>
                                <Space>
                                    <Col >
                                        <Form.Item
                                            label={'Position Name'}
                                            name={`name`}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Input something!',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Position" />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            label={'Section'}
                                            name={`sectionsId`}
                                        >
                                            <Select style={{ width: '150px' }}>
                                                {dataSection.map(e => {
                                                    return (
                                                        <Select.Option value={e.id}>{e.name}</Select.Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Space>

                            </Row>
                            <Row justify={'center'}>
                                <Col>
                                    <Button type="primary" htmlType="submit">
                                        Add Position
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Col>
                <Col span={4}></Col>
            </Row>
        </div >
    )
}