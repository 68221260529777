const deleteOvertime = (id, cb) => {
    let token = sessionStorage.getItem("token");
    token = JSON.parse(token);
    fetch(`${process.env.REACT_APP_API_URL_HELLOTEAM}api/v2/overtime/delete/${id}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token.token}`,
            "Content-Type": "application/json",
        },
    })
        .then((res) => res.json())
        .then((json) => cb(null, json))
        .catch((err) => cb(err, null));
};

export default deleteOvertime;
