import React, { useEffect, useState } from "react";
import { Button, Row, Modal, notification } from "antd";

import deleteOvertime from "../../api/overtime/deleteOvertime";

export default function ModaldeleteOvertime(prop) {

  const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [api, contextHolder] = notification.useNotification();
  useEffect(() => {
    setModalOpen(prop.modalOpen);
    setName(prop.data.name);
    setId(prop.data.id);
  }, [prop]);
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };
  return (
    <>
      <Modal
        title={<div style={{ color: '#EF3C40' }}>{`Delete ${name}`}</div>}
        open={modalOpen}
        footer={null}
        onCancel={() => {
          prop.onClose();
        }}
        closable={true}
      >
        {contextHolder}
        <div
          style={{
            fontSize: "16px",
            color: "#4F4F4F",
            fontWeight: "normal",
            textAlign: "center",
            marginBottom:'10px',
          }}
        >
          You’re going to delete this overtime. Are you sure?
        </div>
        <Row style={{ justifyContent: "center", }}>
          <Button
            htmlType="button"
            style={{
              boxShadow: "unset",
              textShadow: "unset",
              borderRadius: "8px",
              fontSize: "14px",
              margin: "0 0 0px 10px",
              fontWeight: "500",
            }}
            onClick={() => {
              prop.onClose();
            }}
          >
            No, Deep It.
          </Button>
          <Button
            type="primary"
            htmlType="button"
            style={{
              boxShadow: "unset",
              textShadow: "unset",
              borderRadius: "8px",
              fontSize: "14px",
              margin: "0 0 0px 10px",
              fontWeight: "500",
            }}
            danger
            onClick={() => {
              deleteOvertime(id, (err, res) => {
                if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                  prop.handleUpdated();
                  prop.onClose();
                } else {
                  openNotificationWithIcon('error', 400, err ?? 'error not known');
                }
              });
            }}
          >
            Yes, Delete!
          </Button>
        </Row>
      </Modal>
    </>
  );
}
