import React, { useState, useEffect, } from 'react'
import { Menu, } from 'antd'
import ModaleditGroupPermisstion from '../editFormGroupPermisstion'
import ModaladdEmployeeGroupPermisstion from '../addEmployeeFormGroupPermisstion'
import ModaldeleteGroupPermisstion from '../modalDeleteGroupPermisstion'
import './popupmenu.css'

function getItem(label, key, icon, children, type, onClick, disabled) {
    return {
        key,
        icon,
        children,
        label,
        type,
        onClick,
        disabled
    }
}
const PopupMenuNodes = ({ id, top, left, right, bottom, ...props }) => {
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
    const menu = [
        getItem(
            'Edit ',
            'edit',
            null,
            null,
            null,
            () => { setModalEditOpen(!modalEditOpen) },
            false
        ),
        getItem(
            'Add employee',
            'add employee',
            null,
            null,
            null,
            () => { setModalAddOpen(!modalAddOpen) },
            false
        ),
        getItem(
            'Delete',
            'delete',
            null,
            null,
            null,
            () => { setModalDeleteOpen(!modalDeleteOpen) },
            props.data.detail.children.length > 0 || props.data.detail.employees.length > 0 ? true : false
        )
    ];
    return (
        <>
            <Menu
                className="popup"
                items={[...menu]}
                style={{
                    width: 150,
                    top, left, right, bottom,
                }}
            />
            <ModaladdEmployeeGroupPermisstion
                modalOpen={modalAddOpen}
                data_employee={props.dataEmployee}
                data={props.data}
                name={props.data.label}
                handleUpdated={() => { props.handleUpdated(); }}
                onClose={() => {
                    setModalAddOpen(false);
                }}
            />
            <ModaldeleteGroupPermisstion
                modalOpen={modalDeleteOpen}
                data={props.data}
                name={props.data.label}
                handleUpdated={() => { props.handleUpdated(); }}
                onClose={() => {
                    setModalDeleteOpen(false);
                }}
            />
            <ModaleditGroupPermisstion
                data={props.data} modalOpen={modalEditOpen} name={props.data.label}
                handleUpdated={() => { props.handleUpdated(); }}
                onClose={() => {
                    setModalEditOpen(false);
                }}
            />
        </>
    )
};

export default PopupMenuNodes