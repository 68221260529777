
import React, { useEffect, useState } from "react";
import {
    Modal,
    Spin,
    notification,
    Image
} from "antd";

import _ from 'lodash'

export default function ModalPicture(prop) {
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [name, setName] = useState('');
    const [editData, setEditData] = useState(null);
    const [api, contextHolder] = notification.useNotification();
    const headers = new Headers();
    headers.set('Authorization', `Bearer ${process.env.REACT_APP_LINE_CHANNEL_ACCESS_TOKEN}`);
    const openNotificationWithIcon = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    };
    useEffect(() => {
        if (prop.modalOpen) {
            setName(prop.data.name)
            setModalEditOpen(prop.modalOpen);
            fetchData(prop.data.picURL)
        }
    }, [prop]);
    const fetchData = async (picURL) => {
        try {
            const response = await fetch(`https://api-data.line.me/v2/bot/message/${picURL}/content`, {
                headers
            });

            if (response.ok) {
                const imageUrl = URL.createObjectURL(await response.blob());
                setEditData(imageUrl);
            } else {
                console.error('Failed to fetch image');
                openNotificationWithIcon('error', 400, 'Failed to fetch image');
            }
        } catch (error) {
            console.error('Error fetching image:', error);
            openNotificationWithIcon('error', 400, 'Error fetching image:' + error);
        }
    };
    return (
        <>
            <Modal
                title={<div style={{ color: '#2196F3' }}>{`${name}`}</div>}
                open={modalEditOpen}
                onCancel={() => {
                    setModalEditOpen(false);
                    prop.onClose();
                }}
                footer={null}
                closable={true}
                width={500}
            >
                {contextHolder}
                <div style={{ textAlign: 'center' }}>
                    {!_.isEmpty(editData) ? <Image
                        width={200}
                        src={editData}
                    /> :  <Spin tip="Loading"></Spin>}
                </div>
            </Modal>
        </>
    );
}
