import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Modal,
  message,
  notification,
  Select, DatePicker, Space
} from "antd";
import dayjs from 'dayjs';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import editBroadcast from "../../api/broadcast/editBroadcast";



export default function ModaleditBroadcast(prop) {
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [name, setName] = useState('');
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  useEffect(() => {
    setEditData(prop.data);
    setName(prop.data.exmple)
    setModalEditOpen(prop.modalOpen);
    form.setFieldsValue({ status: prop.data.status, dateBroadcast: dayjs(prop.data.dateBroadcast), Messages: prop.data.Messages.map(e => { return { detail: e.detail } }) });
  }, [prop]);
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };
  return (
    <>
      <Modal
        title={<div style={{ color: '#2196F3' }}>{`Edit ${name}`}</div>}
        open={modalEditOpen}
        onCancel={() => {
          setModalEditOpen(false);
          form.resetFields();

          prop.onClose();
        }}
        footer={null}
        closable={true}
        width={1000}
      >
        {contextHolder}
        <Form
          autoComplete="off"
          layout="horizontal"
          size="large"
          form={form}
          style={{ padding: '10px' }}
          onFinish={(e) => {
            editBroadcast(
              editData.id,
              e,
              (err, res) => {
                if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                  prop.handleUpdated();
                  setModalEditOpen(false);
                  prop.onClose();
                  message.success("Edit success");
                  form.resetFields();
                } else {
                  openNotificationWithIcon('error', 400, err ?? 'error not known');
                }
              }
            );
          }}
        >
          <Row justify={'center'} >
            <Col span={8}>
              <Form.Item
                label={'Date'}
                name={`dateBroadcast`}

                rules={[
                  {
                    required: true,
                    message: 'Input something!',
                  },
                ]}
              >
                <DatePicker showTime format="YYYY-MM-DD HH:mm" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={'Status'}
                name={`status`}
                style={{ minWidth: '100px' }}
                rules={[
                  {
                    required: true,
                    message: 'Input something!',
                  },
                ]}
              >
                <Select>
                  <Select.Option value={1}>Ready to send</Select.Option>
                  <Select.Option value={2}>Draft</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'center'} >
            <Col span={24}>
              <Form.List name="Messages" layout="vertical">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, value, ...restField }) => (
                      <Row justify={'center'} style={{ gap: '8px' }}>
                        <Col
                          key={key}
                          span={12}
                        >
                          <Form.Item
                            label={"Messages NO." + (name + 1)}
                            {...restField}
                            name={[name, 'detail']}
                            rules={[
                              {
                                required: true,
                                message: "Message_" + (name + 1),
                              },
                            ]}
                          >
                            <Input.TextArea placeholder={"Message_" + (name + 1)} showCount maxLength={500} />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Col>
                      </Row>
                    ))}
                    <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', flexWrap: 'wrap', }}>
                      <Row justify={'center'}>
                        <Col>
                          {fields.length < 3 && (
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{ width: '500px' }}>
                              Add Messages
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
          <Row justify="center" style={{ padding: "10px 25.57px", gap: "8px" }}>
            <Button
              htmlType="button"
              style={{
                boxShadow: "unset",
                textShadow: "unset",
                borderRadius: "8px",
                fontSize: "14px",
                fontWeight: "500",
              }}
              onClick={() => {
                form.resetFields();
                prop.onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              style={{
                boxShadow: "unset",
                textShadow: "unset",
                borderRadius: "8px",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Edit Broadcast
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
