const getLevelEmployee = (id, cb) => {
    let token = sessionStorage.getItem('token')
    token = JSON.parse(token)
    fetch(`${process.env.REACT_APP_API_URL_HELLOTEAM}api/v2/employee/levelbyemployee/${id}`, {
        method: 'GET',
        headers: new Headers({
            Authorization: `Bearer ${token.token}`,
            Accept: 'application/json'
        })
    })
        .then(res => res.json())
        .then(json => cb(null, json))
        .catch(err => cb(err, null))
}

export default getLevelEmployee
