import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Modal,
  message,
  Table,
  notification,
  Space
} from "antd";
import _ from 'lodash'

import addEmployeeGroupPermisstion from "../../api/grouppermisstion/addEmployeeGroupPermisstion"


export default function ModaladdEmployeeGroupPermisstion(prop) {
  const [modaladdEmployeeOpen, setModaladdEmployeeOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [selectedKeys, setSelectedKeys] = useState([])
  const [employeeData, setEmployeeData] = useState([])
  const [name, setName] = useState('');
  const [id, setID] = useState('');
  const [form] = Form.useForm();
  const columns = [
    {
      title: 'Employee id',
      dataIndex: 'employeeId',
      align: 'center',
    },
    {
      title: 'Firstname - Lastname ',
      dataIndex: 'name',
      render: (_, record) => {
        let name_title = record.title.name + record.firstname_th + ' ' + record.lastname_th
        return name_title
      },
    },
    {
      title: 'Nickname',
      dataIndex: 'nickname',
    },
  ];

  useEffect(() => {
    if (!_.isEmpty(prop.data)) {

      setSelectedKeys(prop.data.detail.employees.map(e => e.employee.id))
      setEmployeeData(prop.data_employee.map(e => { return { ...e, key: e.id } }))
      setName(prop.name)
      setModaladdEmployeeOpen(prop.modalOpen);
      setID(prop.data.detail.id);
    }
  }, [prop]);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedKeys(newSelectedRowKeys);
  };
  return (
    <>
      <Modal
        title={<div style={{ color: '#2196F3' }}>{`Add Employee to Group ${name}`}</div>}
        open={modaladdEmployeeOpen}
        onCancel={() => {
          setModaladdEmployeeOpen(false);
          form.resetFields();
          prop.onClose();
        }}
        footer={null}
        closable={true}
        width={1000}
      >
        {contextHolder}
        <Row>
          <Col span={24}>
            <Table rowSelection={{
              selectedRowKeys: selectedKeys,
              onChange: onSelectChange,
            }}
              columns={columns} dataSource={employeeData} />
          </Col>
        </Row>
        <Row justify={'center'}>
          <Col>
            <Space>
              <Button
                htmlType="button"
                style={{
                  boxShadow: "unset",
                  textShadow: "unset",
                  borderRadius: "8px",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
                onClick={() => {
                  prop.onClose();
                }}
              >
                Cancel
              </Button>
              <Button type="primary" htmlType="submit"
                onClick={() => {
                  addEmployeeGroupPermisstion(id, { employees: selectedKeys }, (err, res) => {
                    if (err) {
                      console.log(err);
                    }
                    if (!err && res.statusCode === 200) {
                      prop.handleUpdated();
                      prop.onClose();
                    } else {
                      message.error(res?.message ?? 'fail')
                    }
                  });
                }}>
                Save Employee
              </Button>
            </Space>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
