import React, { useEffect, useState } from 'react'
import { Space, Table, Switch, message } from 'antd';
import { EditFilled, DeleteFilled } from '@ant-design/icons'
import moment from 'moment';
import Component_edit from './modaleditHoliday';
import enableHoliday from '../../api/holiday/enableHoliday';
import Component_delete from './modalDeleteHoliday';
export default function TableHoliday(props) {
    const [datas, setDatas] = useState([]);
    const [isLoading, setIsloading] = useState(true);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
    const [objOne, setObjone] = useState({});
    const columns = [
        // {
        //     position: 'Holiday',
        //     dataIndex: 'id',
        //     key: 'id',
        // },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (_, record) => {
                return <div>{moment(_).format('DD/MM/YYYY')}</div>
            },
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => {
                return <div>
                    <Switch checked={record.enable} onClick={() => {
                        enableHoliday(record.id, (err, res) => {
                            if (err) {
                                console.log(err);
                            }
                            if (!err && res.statusCode === 200) {
                                props.handleUpdated();
                            } else {
                                message.error(res?.message ?? 'fail')
                            }
                        })
                    }} />
                </div>
            }
        },
        {
            title: 'Manage',
            key: 'manage',
            render: (_, record) => (
                <Space size="middle">
                    <EditFilled onClick={() => { setObjone(record); setModalEditOpen(true) }} />
                    <DeleteFilled onClick={() => { setObjone(record); setModalDeleteOpen(true) }} />
                </Space>
            ),
        },
    ];
    useEffect(() => {
        setDatas(props.data);
        setIsloading(props.isLoading)
        return () => {
            // cleanup
        };
    }, [props]);

    return (
        <>
            <Table loading={isLoading} columns={columns} dataSource={datas} tableLayout={'fixed'} />
            <Component_edit
                handleUpdated={() => { props.handleUpdated() }}
                data={objOne}
                modalOpen={modalEditOpen}
                onClose={() => {
                    setModalEditOpen(false);
                }}
            />
            <Component_delete
                handleUpdated={() => { props.handleUpdated() }}
                data={objOne}
                modalOpen={modalDeleteOpen}
                onClose={() => {
                    setModalDeleteOpen(false);
                }}
            />
        </>
    )
}
