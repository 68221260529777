import React, { useEffect, useState } from "react";
import {
    Form,
    Input,
    Button,
    Row,
    Col,
    Modal,
    message,
    notification
} from "antd";
import { useNavigate, } from 'react-router-dom'
import ResetPassword from '../../api/changePassword'

export default function ModalchangePassword(prop) {
    const [modalResetOpen, setModalResetOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    useEffect(() => {
        setModalResetOpen(prop.modalOpen);
        form.resetFields();
    }, [prop]);
    const openNotificationWithIcon = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    };
    return (
        <>
            <Modal
                title={`ChangePassword`}
                open={modalResetOpen}
                onCancel={() => {
                    setModalResetOpen(false);
                    form.resetFields();
                    prop.onClose();
                }}
                footer={null}
                closable={true}
                width={500}
            >
                {contextHolder}
                <Form
                    autoComplete="off"
                    layout="vertical"
                    size="large"
                    form={form}
                    onFinish={(e) => {
                        console.log(e);
                        ResetPassword(
                            e,
                            (err, res) => {
                                if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                                    setModalResetOpen(false);
                                    prop.onClose();
                                    navigate('/login')
                                    message.success("success");
                                    form.resetFields();
                                } else {
                                    openNotificationWithIcon('error', 400, err ?? 'error not known');
                                }
                            }
                        );
                    }}
                >
                    <Row style={{ alignItems: "start" }}>
                        <Col span={24} >
                            <Form.Item
                                label={`Current Password`}
                                autoComplete="off"
                                name="oldpasswd"
                                rules={[
                                    {
                                        required: true,
                                        message: `PLEASE enter your Password`
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password
                                    showCount
                                    className="inputbox"
                                    placeholder={`current password`}
                                    style={{ borderRadius: "8px" }}
                                    maxLength={30}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ alignItems: "start" }}>
                        <Col span={24} >
                            <Form.Item
                                label={`New Password`}
                                autoComplete="off"
                                name="newpasswd"
                                rules={[
                                    {
                                        required: true,
                                        message: `PLEASE enter your new password`
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password
                                    showCount
                                    className="inputbox"
                                    placeholder={`New Password`}
                                    style={{ borderRadius: "8px" }}
                                    maxLength={30}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ alignItems: "start" }}>
                        <Col span={24} >
                            <Form.Item
                                label={`Comfirm Password`}
                                autoComplete="off"
                                name="conmfirmnewpasswd"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: `PLEASE enter your new password`
                                    },

                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('newpasswd') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The new password that you entered do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    showCount
                                    className="inputbox"
                                    placeholder={`Comfirm password`}
                                    style={{ borderRadius: "8px" }}
                                    maxLength={30}
                                    validateStatus="error"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="end" style={{ gap: "8px" }}>
                        <Button
                            htmlType="button"
                            style={{
                                boxShadow: "unset",
                                textShadow: "unset",
                                borderRadius: "8px",
                                fontSize: "14px",
                                fontWeight: "500",
                            }}
                            onClick={() => {
                                form.resetFields();
                                prop.onClose();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            htmlType="submit"
                            type="primary"
                            style={{
                                boxShadow: "unset",
                                textShadow: "unset",
                                borderRadius: "8px",
                                fontSize: "14px",
                                fontWeight: "500",
                            }}
                        >
                            Submit
                        </Button>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}
