import React, { useEffect, useMemo, useRef, useState, useCallback, useLayoutEffect } from 'react'
import ReactFlow, {
    MiniMap,
    Controls,
    ReactFlowProvider,
    Background,
    useNodesState,
    useEdgesState,
    addEdge,
    useReactFlow
} from 'reactflow';
import _ from 'lodash';
import { FloatButton, message, Spin } from 'antd'
import { AppstoreAddOutlined, FolderAddOutlined } from '@ant-design/icons';
import 'reactflow/dist/style.css';
import ELK from 'elkjs/lib/elk.bundled.js';

import getAllGroupPermisstion from '../../../api/grouppermisstion/getAllGroupPermisstion';
import getAllEmployee from '../../../api/employee/getAll';
import connectNode from '../../../api/grouppermisstion/connectNode';


import Component_detail from '../../../components/group/detailGroupPermisstion';
import Component_add from '../../../components/group/addFormGroupPermisstion';
import Component_confirmDelete from '../../../components/group/deleteEdge';


import PopupMenuBackground from '../../../components/group/popup/PopupMenuBackground';
import PopupMenuNode from '../../../components/group/popup/PopupMenuNode';
import DeleteEdge from '../../../components/group/edges/deleteEdge';
import rootNode from '../../../components/group/nodes/Rootnode';
import middleNode from '../../../components/group/nodes/Middlenode';
import lowerNode from '../../../components/group/nodes/Lowernode';


const elk = new ELK();

function GroupPermisstion(props) {
    const [messageApi, contextHolder] = message.useMessage();
    const ref = useRef(null);
    const [isFetch, setIsfetch] = useState(false);
    const [isLoading, setIsloading] = useState(true);
    const [bodyDelete, setBodyDelete] = useState("");
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
    const [is_Nodes, setIsnodes] = useState([]);
    const [is_Edges, setIsedges] = useState([]);
    const [employee_D, setEmployee] = useState([]);
    const [is_PopupMB, setIspopupmb] = useState({
        visible: false,
        x: 0, y: 0
    });
    const [menu, setMenu] = useState(null);
    const [nodes, setNodes, onNodesChange] = useNodesState(is_Nodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(is_Edges);
    const { fitView } = useReactFlow();
    const [captureElementClick, setCaptureElementClick] = useState({});

    const onConnect = useCallback((params) => setEdges((eds) => {
        console.log('send api to conect');
        let body = {
            id: parseInt(params.target),
            pestentId: parseInt(params.source)
        };
        connectNode(body, (err, res) => {
            if (err) {
                console.log(err);
            }
            if (!err && res.statusCode === 200) {
                setIsloading(true);
                setTimeout(() => {
                    fetch_data();
                }, 1000);
            } else {
                message.error(res?.message ?? 'fail')
                return false;
            }
        });
        // return addEdge(Object.assign(params, { type: 'deleteEdge' }), eds)

    }), [setEdges]);
    const onLayout = useCallback(
        ({ direction, useInitialNodes = false, initialNodes, initialEdges }) => {
            const opts = { 'elk.direction': direction, ...elkOptions };
            const ns = useInitialNodes ? initialNodes : nodes;
            const es = useInitialNodes ? initialEdges : edges;
            getLayoutedElements(ns, es, opts).then(({ nodes: layoutedNodes, edges: layoutedEdges }) => {
                setNodes(layoutedNodes);
                setEdges(layoutedEdges);

                window.requestAnimationFrame(() => fitView());
            });
        },
        [nodes, edges]
    );

    const nodeTypes = useMemo(() => ({ special: rootNode, middlenode: middleNode, lowernode: lowerNode, }), []);

    useLayoutEffect(() => {
        if (!isFetch) {
            fetch_data();
        }
    }, []);

    const fetch_data = () => {
        getAllGroupPermisstion((err, res) => {
            if (err) {
                console.log(err);
            }
            if (!err && res.statusCode === 200) {
                setIsnodes(res.data.initialNodes)
                setIsedges(res.data.initialEdges)
                onLayout({ direction: 'DOWN', useInitialNodes: true, initialNodes: res.data.initialNodes, initialEdges: res.data.initialEdges });
                setCaptureElementClick({})
                setIsfetch(true);
                setIsloading(false);
            } else {
                message.error(res?.message ?? 'fail')
            }
        });
        getAllEmployee((err, res) => {
            if (!err && res.statusCode === 200) {
                setEmployee(res.data)
            }
        })
    }
    return (
        <Spin spinning={isLoading}>
            <div style={{ padding: '20px', margin: '5px 5px 15px 20px' }}>
                <div style={{ width: '80vw', height: '80vh' }} >
                    {contextHolder}
                    {isFetch && (
                        <>
                            <ReactFlow
                                ref={ref}
                                nodes={nodes}
                                edges={edges}
                                onNodeClick={(event, node) => { setCaptureElementClick(node) }}
                                onNodesChange={onNodesChange}
                                onEdgesChange={onEdgesChange}
                                onConnect={onConnect}
                                isValidConnection={(connection) => {
                                    const source = is_Nodes.find(e => e.id === connection.source);
                                    const target = is_Nodes.find(e => e.id === connection.target);

                                    if (source.data.detail.level >= target.data.detail.level) {
                                        console.log('source is hight level');
                                        return false;
                                    }
                                    if ((source.data.detail.level - target.data.detail.level) !== -1) {
                                        return false;
                                    }
                                    return connection;
                                }}
                                nodeTypes={nodeTypes}
                                edgeTypes={{
                                    deleteEdge: (e) => {
                                        return DeleteEdge(Object.assign({
                                            onclick: (res) => {
                                                console.log('sent api disconect');
                                                let body = {
                                                    id: parseInt(e.target),
                                                    pestentId: parseInt(e.source)
                                                };
                                                setModalDeleteOpen(true);
                                                setBodyDelete(body);
                                                // setEdges((eds) => eds.filter((e) => e.id !== res))
                                            }
                                        }, e))
                                    }
                                }}
                                fitView
                            >
                                <Background variant="dots" gap={12} size={1} />
                                {!_.isEmpty(captureElementClick) && (<Component_detail data={captureElementClick}
                                    dataEmployee={employee_D}
                                    handleUpdated={() => {
                                        setIsloading(true);
                                        setTimeout(() => {
                                            fetch_data();
                                        }, 1000);
                                    }} />)}
                            </ReactFlow>
                            <FloatButton
                                type="primary"
                                style={{
                                    right: 100,
                                }}
                                icon={<FolderAddOutlined />}
                                onClick={() => { setModalAddOpen(!modalAddOpen); }}
                                tooltip={<div>Add Group Permisstion</div>}
                            >

                            </FloatButton>
                            <Component_add
                                handleUpdated={() => {
                                    setIsloading(true);
                                    setTimeout(() => {
                                        fetch_data();
                                    }, 1000);
                                }}
                                modalOpen={modalAddOpen}
                                onClose={() => {
                                    setModalAddOpen(false);
                                }}
                            />

                            <Component_confirmDelete
                                handleUpdated={() => {
                                    setIsloading(true);
                                    setTimeout(() => {
                                        fetch_data();
                                    }, 1000);
                                }}
                                body={bodyDelete}
                                modalOpen={modalDeleteOpen}
                                onClose={() => {
                                    setModalDeleteOpen(false);
                                }}
                            />
                        </>
                    )}
                </div >
            </div >
        </Spin>
    )
}
export default () => {

    return (
        <ReactFlowProvider>
            <GroupPermisstion />
        </ReactFlowProvider>
    )
}








const elkOptions = {
    'elk.algorithm': 'mrtree',
    'elk.layered.spacing.nodeNodeBetweenLayers': '100',
    'elk.spacing.nodeNode': '80',
};
const getLayoutedElements = (nodes, edges, options = {}) => {
    const isHorizontal = options?.['elk.direction'] === 'RIGHT';
    const graph = {
        id: 'root',
        layoutOptions: options,
        children: nodes.map((node) => ({
            ...node,
            // Adjust the target and source handle positions based on the layout
            // direction.
            targetPosition: isHorizontal ? 'left' : 'top',
            sourcePosition: isHorizontal ? 'right' : 'bottom',

            // Hardcode a width and height for elk to use when layouting.
            width: 150,
            height: 50,
        })),
        edges: edges,
    };

    return elk
        .layout(graph)
        .then((layoutedGraph) => ({
            nodes: layoutedGraph.children.map((node) => ({
                ...node,
                // React Flow expects a position property on the node instead of `x`
                // and `y` fields.
                position: { x: node.x, y: node.y },
            })),

            edges: layoutedGraph.edges,
        }))
        .catch(console.error);
};