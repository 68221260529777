import React from 'react';
import { BaseEdge, EdgeLabelRenderer, getStraightPath } from 'reactflow';
import { Button, Space } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {stroke: '#818283',color:'#818283'},
  markerEnd,
  onclick
}) {
  const [edgePath, labelX, labelY] = getStraightPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: 'all',
          }}
          className="nodrag nopan"
        >
          <Button
            danger
            type="primary"
            className="edgebutton"
            shape="circle"
            size='small'
            icon={<CloseOutlined />}
            onClick={(event) => { event.stopPropagation(); onclick(id) }}
          ></Button>
        </div>
      </EdgeLabelRenderer >
    </>
  );
}
