import React, { useEffect, useState } from 'react'
import { Row, Col, Typography } from 'antd';
import dayjs from 'dayjs';
import './style.css';
const { Title } = Typography

export default function Clock_week(props) {
    const [timer, setTimer] = useState(dayjs().format('HH:mm A'));
    setInterval(() => {
        setTimer(dayjs().format('HH:mm A'))
    }, 30000);
    useEffect(() => {
        // var now = new Date(),
        //     hourDeg = now.getHours() / 12 * 360 + now.getMinutes() / 60 * 30,
        //     minuteDeg = now.getMinutes() / 60 * 360 + now.getSeconds() / 60 * 6,
        //     secondDeg = now.getSeconds() / 60 * 360,
        //     stylesDeg = [
        //         "@-webkit-keyframes rotate-hour{from{transform:rotate(" + hourDeg + "deg);}to{transform:rotate(" + (hourDeg + 360) + "deg);}}",
        //         "@-webkit-keyframes rotate-minute{from{transform:rotate(" + minuteDeg + "deg);}to{transform:rotate(" + (minuteDeg + 360) + "deg);}}",
        //         "@-webkit-keyframes rotate-second{from{transform:rotate(" + secondDeg + "deg);}to{transform:rotate(" + (secondDeg + 360) + "deg);}}",
        //         "@-moz-keyframes rotate-hour{from{transform:rotate(" + hourDeg + "deg);}to{transform:rotate(" + (hourDeg + 360) + "deg);}}",
        //         "@-moz-keyframes rotate-minute{from{transform:rotate(" + minuteDeg + "deg);}to{transform:rotate(" + (minuteDeg + 360) + "deg);}}",
        //         "@-moz-keyframes rotate-second{from{transform:rotate(" + secondDeg + "deg);}to{transform:rotate(" + (secondDeg + 360) + "deg);}}"
        //     ].join("");

        // document.getElementById("clock-animations").innerHTML = stylesDeg;
        return () => {
            // cleanup
        };
    }, [props]);

    return (
        <div style={style.box}>
            <Row justify={'center'} align={'middle'}>
                <Col span={24} ><Title level={3} style={{ color: '#FFF' }}>Hello, Patty</Title></Col>

            </Row>
            <Row  justify={'center'} >
                <Col span={24} ><span style={{ color: 'var(--444444, #444)', fontSize: '1.25rem' }}>{dayjs().format('MMMM Do, YYYY')}</span></Col>
            </Row>
            <Row  justify={'center'} >
                <Col span={24} ><span style={{ color: 'var(--444444, #444)', fontSize: '1.25rem' }}>{timer}</span></Col>
            </Row>
            <Row justify={'center'} align={'middle'}>
                <Col span={24}>
                    {/* <div class="clock-wrapper">
                        <div class="clock-base">
                            <div class="click-indicator">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div class="clock-hour"></div>
                            <div class="clock-minute"></div>
                            <div class="clock-second"></div>
                            <div class="clock-center"></div>
                        </div>
                    </div> */}

                </Col>
            </Row>
        </div>
    )
}

const style = {
    box: {
        display: 'flex',
        // width: '300px',
        height: '380px',
        // padding: '24px 16px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // gap: '12px',
        flexShrink: 0,
        borderRadius: '16px',
        background: '#76B9EA'
    }
}