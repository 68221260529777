import React, { useEffect, useState } from 'react'
import { Table, } from 'antd';


export default function Tableevent(props) {
    const [isLoading, setIsloading] = useState(true);
    const [data, setData] = useState([]);
    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Type',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'By',
            dataIndex: 'by',
            key: 'by',
        },
    ];
    useEffect(() => {
        setIsloading(props.isLoading)
        setData(props.data);
        return () => {
            // cleanup
        };
    }, [props]);

    return (
        <>
            <Table
                loading={isLoading}
                columns={columns}
                dataSource={data}
                scroll={{
                    y: 240,
                }}
                style={{height:290}}
                pagination={false}
            />
        </>
    )
}
