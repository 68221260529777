import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Modal,
  message,
  notification,
  Space,
  Select
} from "antd";
import { useSelector } from 'react-redux'

import editDepartment from "../../api/department/editDepartment";


import { getBusinessUnit } from "../../slice/businessunitSlice";

export default function ModaleditDepartment(prop) {
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [name, setName] = useState('');
  const dataBU = useSelector(getBusinessUnit);
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  useEffect(() => {
    setEditData(prop.data);
    setName(prop.data.name);
    setModalEditOpen(prop.modalOpen);
    form.setFieldsValue({ name: prop.data.name, business_groupsId: prop.data.business_groupsId ?? null })
  }, [prop]);
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };
  return (
    <>
      <Modal
        title={`Edit ${name}`}
        open={modalEditOpen}
        onCancel={() => {
          setModalEditOpen(false);
          form.resetFields();

          prop.onClose();
        }}
        footer={null}
        closable={true}
        width={500}
      >
        {contextHolder}
        <Form
          autoComplete="off"
          layout="vertical"
          size="large"
          form={form}
          onFinish={(e) => {
            editDepartment(
              editData.id,
              e,
              (err, res) => {
                if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                  prop.handleUpdated();
                  setModalEditOpen(false);
                  prop.onClose();
                  message.success("Edit success");
                  form.resetFields();
                } else {
                  openNotificationWithIcon('error', 400, err ?? 'error not known');
                }
              }
            );
          }}
        >
          <Row style={{ alignItems: "start" }}>
            <Space>
              <Col >
                <Form.Item
                  label={`Department Name`}
                  autoComplete="off"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: `input something`
                    },
                  ]}
                >
                  <Input
                    showCount
                    className="inputbox"
                    placeholder={`name`}
                    style={{ borderRadius: "8px" }}
                    maxLength={30}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label={`Business Unit`}
                  name={`business_groupsId`}
                >
                  <Select style={{ width: '150px' }}>
                    {dataBU.map(e => {
                      return (
                        <Select.Option value={e.id}>{e.name}</Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Space>
          </Row>
          <Row justify="end" style={{ padding: "0 25.57px", gap: "8px" }}>
            <Button
              htmlType="button"
              style={{
                boxShadow: "unset",
                textShadow: "unset",
                borderRadius: "8px",
                fontSize: "14px",
                fontWeight: "500",
              }}
              onClick={() => {
                form.resetFields();
                prop.onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              style={{
                boxShadow: "unset",
                textShadow: "unset",
                borderRadius: "8px",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Edit Department
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
