import React, { useEffect, useState } from 'react'
import { Space, Col, Row, Form, Input, message, notification, Button } from 'antd';
import addTitle from '../.././api/title/addTitle'
export default function AddFormTitle(props) {
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    };
    const [form] = Form.useForm();
    const formStyle = {
        maxWidth: 'none',
        padding: 24,
    };

    return (
        <div style={{ padding: '20px', margin: '20px 15px 15px 20px', backgroundColor: "#FFF" }}>
            {contextHolder}
            <Row>
                <Col span={4}></Col>
                <Col span={16}>
                    <Form form={form} name="advanced_search" style={formStyle} onFinish={(e) => {
                        addTitle(e, (err, res) => {
                            if (err) {
                                openNotificationWithIcon('error', 400, err ?? 'error not known');
                            }
                            if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                                form.resetFields();
                                props.handleUpdated();
                                props.onClose();
                                message.success("This is a success add Title");
                            }
                            if (res.statusCode >= 400) {
                                openNotificationWithIcon('error', res.statusCode, res.message);
                            }
                        })
                    }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', flexWrap: 'wrap' }}>
                            <Row>
                                <Col >
                                    <Form.Item
                                        name={`name`}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Input something!',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Title Name" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify={'center'}>
                                <Col>
                                    <Button type="primary" htmlType="submit">
                                        Add Title Name
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Col>
                <Col span={4}></Col>
            </Row>
        </div >
    )
}