import React from 'react'
import { Row, Col, Button, } from 'antd'
import _ from 'lodash';
import { useNavigate, } from 'react-router-dom'

import EditFormEmployee from '../../components/employee/editFormEmployee';
export default function Employeeedit(props) {
    const navigate = useNavigate()
    return (
        <div style={{ padding: '20px', margin: '20px 15px 15px 20px', background: '#FFF', borderRadius: '16px' }}>
            <Row align='middle' justify='space-between' style={{ marginBottom: '10px' }}>
                <Col span={10} >
                    <Row style={{ color: "#2196F3", marginBottom: '10px', fontSize: '1.25rem' }}>Edit Employee</Row>
                </Col>
                <Col span={14}>
                    <Row style={{ flexWrap: 'nowrap', justifyContent: 'flex-end', gap: '8px', marginBottom: '10px' }}>
                        <Button
                            type={'primary'}
                            style={{
                                borderRadius: '8px',
                                fontWeight: '500',
                                boxShadow: "unset",
                                textShadow: "unset",
                            }}
                            onClick={() => navigate('/employee')}
                        >
                            Back
                        </Button>
                    </Row>
                </Col>
            </Row>
            <EditFormEmployee />
        </div>
    )
}
