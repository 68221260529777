import React, { useEffect, useState } from 'react'
import { Table, Space, Tooltip } from 'antd';
import { WechatOutlined, EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons'
import Component_view from './overtimeTimeline'
import Component_edit from './editOvertime'
import Component_delete from './modalDeleteOvertime'
import Component_Picture from '../worktime/modalPicture'


export default function Tableovertime(props) {
    const [isLoading, setIsloading] = useState(true);
    const [modalViewOpen, setModalViewOpen] = useState(false);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
    const [modalPictureOpen, setModalPictureOpen] = useState(false);
    const [objOne, setObjone] = useState({});
    const columns = [
        {
            title: 'No.',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Employee ID',
            dataIndex: 'employeeId',
            key: 'employeeId',
            sorter: (a, b) => a.employeeId - b.employeeId,
            align: 'center',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            sorter: (a, b) => new Date(a.sortStartDate) - new Date(b.sortStartDate),
        },
        {
            title: 'Check in (OT)',
            dataIndex: 'checkin',
            key: 'checkin',
        },
        {
            title: 'Location',
            dataIndex: 'locationin',
            key: 'locationin',
        },
        {
            title: 'Check In Pic',
            dataIndex: 'starttime_pic',
            key: 'starttime_pic',
            render(_, record) {
                if (_ === '-') {
                    return _
                } else {
                    return <div style={{ textAlign: 'center' }}>
                        <EyeOutlined style={{ fontSize: '18px', color: '#2feba3' }}
                            onClick={() => { setObjone({ picURL: _, ...record }); setModalPictureOpen(true) }} />
                    </div>
                }
            },
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            sorter: (a, b) => new Date(a.sortEndDate) - new Date(b.sortEndDate),
        },
        {
            title: 'Check in (OT)',
            dataIndex: 'checkout',
            key: 'checkout',
        },
        {
            title: 'Location',
            dataIndex: 'locationout',
            key: 'locationout',
        },
        {
            title: 'Check Out Pic',
            dataIndex: 'endtime_pic',
            key: 'endtime_pic',
            render(_, record) {
                if (_ === '-') {
                    return _
                } else {
                    return <div style={{ textAlign: 'center' }}>
                        <EyeOutlined style={{ fontSize: '18px', color: '#eb2f96' }}
                            onClick={() => { setObjone({ picURL: _, ...record }); setModalPictureOpen(true) }} />
                    </div>
                }
            },

        },
        {
            title: 'Summary (OT)',
            dataIndex: 'sumtotal',
            key: 'sumtotal',
            align: 'center',
            render(_, record) {
                var sumtotal = '-'
                if (String(_).split('.').length > 1) {
                    sumtotal = String(_).split('.')[0] + "  hr." + ' 30 min'
                } else { sumtotal = String(_) + "  hr." + ' 0 min' }
                return sumtotal
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (_, record) => (
                <Space size="middle">
                    {record.status_name_hr !== '-' ? record.status_name_hr : record.status_name}
                </Space>
            ),
        },
        {
            title: 'Business Unit',
            dataIndex: 'business_unit',
            key: 'business_unit',
            align: 'center',
        },
        {
            title: 'Manage',
            key: 'manage',
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip title="Overtime Detail" placement="bottom" ><WechatOutlined onClick={() => { setObjone(record); setModalViewOpen(true) }} /></Tooltip>
                    <Tooltip title="Edit" placement="bottom" ><EditOutlined onClick={() => { setObjone(record); setModalEditOpen(true) }} /></Tooltip>
                    <Tooltip title="Delete" placement="bottom" ><DeleteOutlined onClick={() => { setObjone(record); setModalDeleteOpen(true) }} /></Tooltip>
                </Space>
            ),
        },
    ];
    useEffect(() => {
        setIsloading(props.isLoading)
        return () => {
            // cleanup
        };
    }, [props]);

    return (
        <>
            <Table
                loading={isLoading}
                columns={columns}
                tableLayout={'fixed'}
                dataSource={props.data.map((e, i) => { return { ...e, 'key': i + 1 } })}
            />
            <Component_view
                handleUpdated={() => { props.handleUpdated() }}
                data={objOne}
                modalOpen={modalViewOpen}
                onClose={() => {
                    setModalViewOpen(false);
                }}
            />
            <Component_edit
                handleUpdated={() => { props.handleUpdated() }}
                data={objOne}
                modalOpen={modalEditOpen}
                onClose={() => {
                    setModalEditOpen(false);
                }}
            />
            <Component_delete
                handleUpdated={() => { props.handleUpdated() }}
                data={objOne}
                modalOpen={modalDeleteOpen}
                onClose={() => {
                    setModalDeleteOpen(false);
                }}
            />
            <Component_Picture
                data={objOne}
                modalOpen={modalPictureOpen}
                onClose={() => {
                    setModalPictureOpen(false);
                }}
            />
        </>
    )
}
