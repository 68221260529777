import React from 'react'
import { Routes, Route, Navigate, } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import './App.css';
import Layout from './layout/main'
import useToken from './utils/useToken'
import Login from './pages/login'
import Notfound from './pages/status/404'
import Overview from './pages/overview'
import Employee from './pages/employee'
import Worktime from './pages/workTime'
import Leave from './pages/leave'
import Overtime from './pages/overtime'
import GroupPermisstion from './pages/setting/groupPermisstion'
import EmployeeDetail from './pages/employee/detail'
import EmployeeEdit from './pages/employee/editEmployee'
import EmployeeEducation from './pages/employee/educationEmployee'
import EmployeeProfile from './pages/employee/profile'
import EmployeeLeave from './pages/employee/leaveEmployee'
import Title from './pages/config/title'
import Position from './pages/config/positions'
import Section from './pages/config/section'
import Business_unit from './pages/config/business_unit'
import Department from './pages/config/department'
import Education from './pages/config/education'
import GroupPosition from './pages/config/groupposition'
import Holiday from './pages/setting/holiday'
import Broadcast from './pages/setting/broadcast'
import ResumeExport from './components/employee/profile/resumeExport'


const PrivateRoute = ({ auth: { isAuthenticated }, children }) => {
  return isAuthenticated ? children : <Navigate to='/login' />
}

function Root() {
  const { token, setToken } = useToken()

  // useEffect(() => {
  //   ConfigProvider.config({
  //     theme: {
  //       primaryColor: '#2196F3'
  //     }
  //   })
  // }, [])
  return (
    <ConfigProvider prefixCls='ant'
      theme={{
        components: {
          Button: {
            colorPrimary: '#2196F3',
            algorithm: true, // Enable algorithm
          },
        },
        token: {
          colorPrimary: '#2196F3',
          colorInfo: "#2196F3",
          colorError: "#EF3C40",
          colorErrorActive: "#E52F33",
          colorErrorBgHover: 'linear-gradient(0deg, rgba(239, 60, 64, 0.70) 0%, rgba(239, 60, 64, 0.70) 100%), #FFF',
          // colorTextBase: "#333",
          colorText: "#818283",
          algorithm: true,
        },
      }} >
      <Routes>
        <Route path='/' element={<Navigate to='/overview' />} />
        
        <Route
          path='/'
          element={
            <PrivateRoute auth={{ isAuthenticated: token }}>
              <Layout token={token} />
            </PrivateRoute>
          }
        >
          <Route index path='overview' element={<Overview />} />
          <Route path='employee' element={<Employee />} />
          <Route path='employee/detail/:id' element={<EmployeeDetail />} />
          <Route path='employee/edit/:id' element={<EmployeeEdit />} />
          <Route path='employee/education/:id' element={<EmployeeEducation />} />
          <Route path='employee/profile/:id' element={<EmployeeProfile />} />
          <Route path='employee/leave/:id' element={<EmployeeLeave />} />
          <Route path='work Time' element={<Worktime />} />
          <Route path='leave' element={<Leave />} />
          <Route path='overtime' element={<Overtime />} />
          <Route path='group Permission' element={<GroupPermisstion />} />
          <Route path='broad cast' element={<Broadcast />} />
          
          <Route path='title Name' element={<Title />} />
          <Route path='positions' element={<Position />} />
          <Route path='sections' element={<Section />} />
          <Route path='business Unit' element={<Business_unit />} />
          <Route path='departments' element={<Department />} />
          <Route path='education Level' element={<Education />} />
          <Route path='group Positions' element={<GroupPosition />} />
          <Route path='holiday Entitlement' element={<Holiday />} />


        </Route>
        <Route path='resume/export/:id' element={<ResumeExport />} />
        <Route path='login' element={<Login setToken={setToken} />} />
        {/* <Route path='demo' element={<Fortest />} /> */}
        <Route path='*' element={<Notfound isAuth={token} />} />
      </Routes>
    </ConfigProvider >
  )
}
export default Root
