import React, { useEffect, useState } from "react";
import {
    Button,
    Row,
    Modal,
    message,
    notification,
    Space
} from "antd";
import dayjs from 'dayjs';
import _ from "lodash";
import editWorktimeE from "../../api/employee/editWorktimeE";



export default function ModaleditWorktimeE(prop) {
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [editData, setEditData] = useState({ worktimes: [] });
    const [name, setName] = useState('');
    const [api, contextHolder] = notification.useNotification();
    useEffect(() => {
        if (!_.isEmpty(prop.data)) {
            setEditData(prop.data);
            setName(prop.data.name);
            setModalEditOpen(prop.modalOpen);
        }
    }, [prop]);
    const openNotificationWithIcon = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    };
    return (
        <>
            <Modal
                title={`Workday Setting ${name}`}
                open={modalEditOpen}
                onCancel={() => {
                    setModalEditOpen(false);
                    prop.onClose();
                }}
                footer={null}
                closable={true}
                width={500}
            >
                {contextHolder}
                <Row justify="center" style={{ marginBottom: "1rem" }}>
                    <Space>
                        {editData.worktimes.map((e) => (
                            <Button
                                type={e.enable ? "primary" : "-"}
                                style={{
                                    boxShadow: "unset",
                                    textShadow: "unset",
                                    borderRadius: "8px",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                }}
                                onClick={() => {
                                    var worktime = editData.worktimes.map((worktime) => {
                                        if (worktime.id === e.id) {
                                            return { ...worktime, enable: !worktime.enable }
                                        } else {
                                            return worktime
                                        }
                                    });
                                    var tmp_editData = { ...editData };
                                    tmp_editData.worktimes = worktime;
                                    setEditData(tmp_editData)
                                }}
                            >
                                {dayjs().day(e.dayworktimeId - 1).format('ddd')}
                            </Button>
                        ))}
                    </Space>
                </Row>
                <Row justify="center" style={{ padding: "0 25.57px", gap: "8px" }}>
                    <Button
                        htmlType="button"
                        style={{
                            boxShadow: "unset",
                            textShadow: "unset",
                            borderRadius: "8px",
                            fontSize: "14px",
                            fontWeight: "500",
                        }}
                        onClick={() => {
                            prop.onClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        style={{
                            boxShadow: "unset",
                            textShadow: "unset",
                            borderRadius: "8px",
                            fontSize: "14px",
                            fontWeight: "500",
                        }}
                        onClick={() => {
                            editWorktimeE(editData.id, { dayofwork: editData.worktimes }, (err, res) => {
                                if (err) {
                                    openNotificationWithIcon('error', 400, err ?? 'error not known');
                                }
                                if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                                    prop.onClose();
                                    prop.handleUpdated();
                                    message.success("This is a success");
                                }
                                if (res.statusCode >= 400) {
                                    openNotificationWithIcon('error', res.statusCode, res.message);
                                }
                            })
                        }}
                    >
                        Set Workday
                    </Button>
                </Row>
            </Modal>
        </>
    );
}
