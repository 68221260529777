import React, { useEffect, useState } from "react";
import { Button, Row, Modal, notification } from "antd";

import deleteEducation from "../../api/employee/deleteEducation";

export default function ModalDeleteEducationem(prop) {

    const [modalOpen, setModalOpen] = useState(false);
    const [name, setName] = useState("");
    const [id, setId] = useState("");
    const [api, contextHolder] = notification.useNotification();
    useEffect(() => {
        setModalOpen(prop.modalOpen);
        setName(prop.data.branch);
        setId(prop.data.id);
    }, [prop]);
    const openNotificationWithIcon = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    };
    return (
        <>
            <Modal
                title={`Delete Education `}
                open={modalOpen}
                footer={null}
                onCancel={() => {
                    prop.onClose();
                }}
                closable={true}
            >
                {contextHolder}
                <div
                    style={{
                        fontSize: "16px",
                        color: "#82828",
                        fontWeight: "normal",
                    }}
                >
                    You’re going to delete {name}. Are you sure?
                </div>
                <Row style={{ justifyContent: "end", padding: "0" }}>
                    <Button
                        htmlType="button"
                        style={{
                            boxShadow: "unset",
                            textShadow: "unset",
                            borderRadius: "8px",
                            fontSize: "14px",
                            margin: "0 0 0px 10px",
                            fontWeight: "500",
                        }}
                        onClick={() => {
                            prop.onClose();
                        }}
                    >
                        No, Deep It.
                    </Button>
                    <Button
                        type="primary"
                        htmlType="button"
                        style={{
                            boxShadow: "unset",
                            textShadow: "unset",
                            borderRadius: "8px",
                            fontSize: "14px",
                            margin: "0 0 0px 10px",
                            fontWeight: "500",
                        }}
                        danger
                        onClick={() => {
                            deleteEducation(id, (err, res) => {
                                if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                                    prop.handleUpdated();
                                    prop.onClose();
                                } else {
                                    openNotificationWithIcon('error', 400, err ?? 'error not known');
                                }
                            });
                        }}
                    >
                        Yes, Delete!
                    </Button>
                </Row>
            </Modal>
        </>
    );
}
