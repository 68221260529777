import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Input, message } from 'antd'
import { SearchOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'

import Tableemployee from '../../components/employee/tableEmployee';
import AddFormEmployee from '../../components/employee/addFormEmployee';
import handlePrint from '../../components/employee/handlePrintfortax';

import getAllEmployee from '../../api/employee/getAll';

export default function Employee(props) {
    const [isFetch, setIsfetch] = useState(false);
    const [addButton, setAddbutton] = useState(false);
    const [isLoading, setIsloading] = useState(true);
    const [datas, setDatas] = useState([]);
    const [datasFilter, setDatasFilter] = useState([]);
    useEffect(() => {
        if (!isFetch) {
            fetch_data();
        }

        return () => {
            // cleanup
        };
    }, []);
    const fetch_data = () => {
        getAllEmployee((err, res) => {
            if (err) {
                console.log(err);
            }
            if (!err && res.statusCode === 200) {
                let tmp_data = res.data.sort((a, b) => a.employeeId - b.employeeId).map((leave, index) => { return { ...leave, key: index + 1 } });
                setDatas(tmp_data);
                setDatasFilter(tmp_data);
                setIsfetch(true);
                setIsloading(false);
                // message.success('success')
            } else {
                message.error(res?.message ?? 'fail')
            }
        })
    }
    return (
        <div style={{ padding: '20px', margin: '20px 15px 15px 20px', background: '#FFF', borderRadius: '16px' }}>
            <Row align='middle' justify='space-between' style={{ marginBottom: '10px' }}>
                <Col span={10} style={{ color: "var(--ant-primary-color)" }}>
                    {addButton ? 'Add New Employee ' : ''}
                </Col>
                <Col span={14}>
                    <Row style={{ flexWrap: 'nowrap', justifyContent: 'flex-end', gap: '8px' }}>
                        {!addButton && (
                            <Input
                                suffix={
                                    <SearchOutlined style={{ color: 'rgba(79, 79, 79, 0.5)' }} />
                                }
                                className='ant-inputsearch search-border'
                                placeholder={`Search....`}
                                style={{
                                    margin: '0px 0px 0px 10px',
                                    borderRadius: '8px',
                                    // border: '1px solid var(--ant-primary-color)',
                                    maxWidth: '200px'
                                }}
                                onChange={e => {
                                    const currValue = e.target.value
                                    const filteredData = datasFilter.filter(entry =>
                                        Object.keys(entry).filter((k) => k).some(s => {
                                            return String(entry[s])
                                                .toLowerCase()
                                                .includes(currValue.toLowerCase())
                                        })
                                    )
                                    setDatas(filteredData)
                                }}
                            />
                        )}
                        {!addButton && (
                            <Button
                                loading={!isFetch}
                                style={{
                                    borderRadius: '8px',
                                    color: '#2196F3',
                                    border: '1px solid ',
                                    // padding: '10px',
                                    //   margin: '20px 10px',
                                    fontWeight: '500',
                                }}
                                ghost
                                onClick={() => { handlePrint(datas); }}
                            >
                                <UploadOutlined /> Export for Tax
                            </Button>
                        )}
                        <Button
                            loading={!isFetch}
                            type={!addButton ? 'primary' : ''}
                            style={{
                                borderRadius: '8px',
                                fontWeight: '500',
                                boxShadow: "unset",
                                textShadow: "unset",
                            }}
                            onClick={() => { setAddbutton(!addButton); setDatas(datasFilter) }}
                        >
                            {!addButton && <PlusOutlined />}
                            {!addButton ? 'Add New Employee' : 'Cancel'}
                        </Button>
                    </Row>
                </Col>
            </Row >
            {addButton && (<AddFormEmployee
                handleUpdated={() => {
                    setAddbutton(!addButton); 
                    setIsloading(true);
                    setTimeout(() => {
                        fetch_data();
                    }, 1000);
                }} />
            )}
            {isFetch && !addButton && (<Tableemployee data={datas} isLoading={isLoading}
                handleUpdated={() => {
                    setIsloading(true);
                    setTimeout(() => {
                        fetch_data();
                    }, 1000);
                }} />
            )}
        </div >
    )
}

