
import React, { useEffect, useState } from "react";
import {
    Form,
    Button,
    Input,
    Row,
    Col,
    Modal,
    message,
    notification,
    TimePicker,
    Space
} from "antd";
import dayjs from 'dayjs';
import _ from 'lodash'

import editWorktime from '../../api/worktime/edit'
import addWorktime from '../../api/worktime/addNew'


export default function ModaleditSWorktime(prop) {
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [name, setName] = useState('');
    const [editData, setEditData] = useState({});
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();
    useEffect(() => {
        if (prop.modalOpen) {
            setName(prop.data.date + ' ' + prop.data.name);
            setEditData(prop.data);
            setModalEditOpen(prop.modalOpen);
            if (prop.data.startdate !== '-') {
                form.setFieldsValue({ sortStartDate: dayjs(prop.data.sortStartDate), location_start: prop.data.location_start });
            }
        }
    }, [prop]);
    const openNotificationWithIcon = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    };
    return (
        <>
            <Modal
                title={<div style={{ color: '#2196F3' }}>{`Edit Check In Time ${name}`}</div>}
                open={modalEditOpen}
                onCancel={() => {
                    setModalEditOpen(false);
                    form.resetFields();
                    prop.onClose();
                }}
                footer={null}
                closable={true}
                width={500}
            >
                {contextHolder}
                <Form
                    autoComplete="off"
                    layout="vertical"
                    size="large"
                    form={form}
                    onFinish={(e) => {
                        var newformatdate = editData.date.split("/")
                        newformatdate = newformatdate[2] + "-" + newformatdate[1] + '-' + newformatdate[0];
                        var body = {
                            createdAt: dayjs(e.sortStartDate).format(newformatdate + ' HH:mm:ss'),
                            userId: editData.userId,
                            location: e.location_start ,
                        }
                        if (editData.startdate === '-') {
                            //add new.
                            body.typeId = 1
                            addWorktime(
                              body,
                              (err, res) => {
                                if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                                  prop.handleUpdated();
                                  setModalEditOpen(false);
                                  prop.onClose();
                                  message.success("success");
                                  form.resetFields();
                                } else {
                                  openNotificationWithIcon('error', 400, err ?? 'error not known');
                                }
                              }
                            );
                        } else {
                            editWorktime(
                              editData.idStartDate,
                              body,
                              (err, res) => {
                                if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                                  prop.handleUpdated();
                                  setModalEditOpen(false);
                                  prop.onClose();
                                  message.success("success");
                                  form.resetFields();
                                } else {
                                  openNotificationWithIcon('error', 400, err ?? 'error not known');
                                }
                              }
                            );
                        }

                    }}
                >
                    <Row justify="center" style={{ alignItems: "center" }}>
                        <Space>
                            <Col style={{ margin: "0 25px 0 0" }}>
                                <Form.Item
                                    label={`Check In Time`}
                                    autoComplete="off"
                                    name="sortStartDate"
                                    rules={[
                                        {
                                            required: true,
                                            message: `input sometings`
                                        },
                                    ]}
                                >
                                    <TimePicker />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    label={`Location Check In`}
                                    name={`location_start`}
                                    rules={[
                                        {
                                            required: true,
                                            message: `input sometings`
                                        },
                                    ]}
                                >
                                    <Input
                                        showCount
                                        className="inputbox"
                                        placeholder={`Location Check In`}
                                        style={{ borderRadius: "8px" }}
                                        maxLength={30}
                                    />
                                </Form.Item>
                            </Col>
                        </Space>
                    </Row>
                    <Row justify="center" style={{ padding: "0 25.57px", gap: "8px" }}>
                        <Button
                            htmlType="button"
                            style={{
                                boxShadow: "unset",
                                textShadow: "unset",
                                borderRadius: "8px",
                                fontSize: "14px",
                                fontWeight: "500",
                            }}
                            onClick={() => {
                                setModalEditOpen(false);
                                form.resetFields();
                                prop.onClose();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            htmlType="submit"
                            type="primary"
                            style={{
                                boxShadow: "unset",
                                textShadow: "unset",
                                borderRadius: "8px",
                                fontSize: "14px",
                                fontWeight: "500",
                            }}
                        >
                            Save Check In Time
                        </Button>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}
