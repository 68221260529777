import React, { useEffect, useState } from 'react'
import { Row, Col, Tabs, Dropdown, Button, Space, message } from 'antd';
import { ProfileOutlined } from "@ant-design/icons";

import Event_table from "../../components/overview/event/index";
import Comming_table from "../../components/overview/event/comming";
import Pending_table from "../../components/overview/event/pending";
import Clock_week from "../../components/overview/clock";

import Box_event from "../../components/overview/week/box_event";
import Week_report from "../../components/overview/week/report";
import Calender_week from "../../components/overview/week/calender_week";

import getAllEventButtonbar from "../../api/overview/getEventbuttombar"
import getAllEventtopbar from "../../api/overview/getEventtopbar"


import { useSelector, } from 'react-redux'
import { getOverView, } from "../../slice/overviewSlice";

export default function Overview(props) {
    const dateToday = useSelector(getOverView);
    const [objEvents_top, setObjevents_top] = useState({ "event": [], "coming": [], "pending": [], });
    const [objEvents_bottom, setObjevents_bottom] = useState({});
    const [isLoading_top, setIsloading_top] = useState(true);
    const [isLoading_bottom, setIsloading_botton] = useState(true);
    useEffect(() => {
        getAllEventButtonbar(dateToday, (err, res) => {
            if (err) {
                console.log(err);
                message.error(err ?? 'fail')
            }
            if (!err && res.statusCode === 200) {
                setObjevents_bottom(res.data);
                setIsloading_botton(false);
            } else {
                message.error(res?.message ?? 'fail')
            }
        });
        return () => {
            // cleanup
        };
    }, [dateToday]);

    useEffect(() => {
        getAllEventtopbar((err, res) => {
            if (err) {
                console.log(err);
                message.error(err ?? 'fail')
            }
            if (!err && res.statusCode === 200) {
                setObjevents_top(res.data);
                setIsloading_top(false);
            } else {
                message.error(res?.message ?? 'fail')
            }
        });
    }, [])
    return (
        <div style={{ padding: "20px", margin: "5px 5px 15px 20px" }}>
            <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
                <Col span={6}>
                    <Clock_week />
                </Col>
                <Col span={18}>
                    <div style={{ background: "#FFF", borderRadius: "16px" }}>
                        <Tabs
                            style={{ padding: "16px 20px" }}
                            type="card"
                            items={["Events", "Coming up", "Pending"].map((_, i) => {
                                const id = String(i + 1);
                                if (i === 0) {
                                    return {
                                        label: `${_}`,
                                        key: id,
                                        children: <Event_table isLoading={isLoading_top} data={objEvents_top.event} />,
                                    };
                                } else if (i === 1) {
                                    return {
                                        label: `${_}`,
                                        key: id,
                                        children: <Comming_table isLoading={isLoading_top} data={objEvents_top.coming} />,
                                    };
                                } else {
                                    return {
                                        label: `${_}`,
                                        key: id,
                                        children: <Pending_table isLoading={isLoading_top} data={objEvents_top.pending} />,
                                    };
                                }
                            })}
                        />
                    </div>
                </Col>
            </Row>
            <Row style={{ marginTop: "24px" }}>
                <Col span={24}>
                    <div
                        style={{
                            background: "#FFF",
                            borderRadius: "16px",
                            padding: "24px 62.5px",
                        }}
                    >
                        <Row style={{ marginBottom: "24px" }}>
                            <Col span={20}>
                                <Calender_week />
                            </Col>
                            <Col span={4} style={{ padding: "12px 0px" }}>
                                <Row
                                    justify="end"
                                    style={{
                                        marginTop: "26px",
                                        marginLeft: "26px",
                                    }}
                                >
                                    <Dropdown
                                        dropdownRender={(menu) => <Week_report />}
                                        trigger={["click"]}
                                    >
                                        <Button
                                            type="primary"
                                            style={{
                                                height: "46px",
                                                width: "100%",
                                                maxWidth: "148px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                <ProfileOutlined style={{ marginRight: "8px" }} />
                                                Weekly Report
                                            </div>
                                        </Button>
                                    </Dropdown>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={[24, 16]}>
                            {Object.keys(objEvents_bottom).map((event, i) => {
                                return (
                                    <Col span={8} key={i}>
                                        <Box_event isLoading={isLoading_bottom} data={objEvents_bottom[event]} label={event} />
                                    </Col>
                                )
                            })}
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    );
}
