import React, { useEffect, useState } from 'react'
import { Table, Space, Tooltip } from 'antd';
import { WechatOutlined, DeleteFilled } from '@ant-design/icons'
import { useSearchParams } from 'react-router-dom'
import _ from 'lodash'

import Component_view from './leaveTimeline'
import Component_delete from './modalDeleteLeave'
export default function Tableemployee(props) {
    const [isLoading, setIsloading] = useState(true);
    const [modalViewOpen, setModalViewOpen] = useState(false);
    const [current, setCurrent] = useState(1);
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
    const [objOne, setObjone] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const params = {};
    const columns = [
        {
            title: 'No.',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'EmployeeId',
            dataIndex: 'employeeId',
            key: 'employeeId',
            sorter: (a, b) => a.employeeId - b.employeeId,
            align: 'center',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'start',
        },
        {
            title: 'Leave Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            sorter: (a, b) => new Date(a.sortStartDate) - new Date(b.sortStartDate),
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            sorter: (a, b) => new Date(a.sortEndDate) - new Date(b.sortEndDate),
        },
        {
            title: 'Summary (day)',
            dataIndex: 'sumtotal',
            key: 'sumtotal',
            align: 'center',

        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (_, record) => (
                <Space size="middle">
                    {record.status === 0 && <span >ไม่อนุมัติ</span>}
                    {record.status === 1 && <span >เริ่มต้น</span>}
                    {record.status === 2 && <span >รออนุมัติ</span>}
                    {record.status === 3 && <span >อนุมัติ</span>}
                    {record.status === -1 && <span >ยกเลิกโดยผู้ใช้งาน</span>}
                    {record.status === -2 && <span >ยกเลิกโดยฝ่ายบุคคล</span>}
                </Space>
            ),
        },
        {
            title: 'Approved By',
            dataIndex: 'approved_by',
            key: 'approved_by',
            align: 'center',
        },
        {
            title: 'Manage',
            key: 'manage',
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip title="Leave Detail" placement="bottom" ><WechatOutlined onClick={() => { setObjone(record); setModalViewOpen(true) }} /></Tooltip>
                    {(record.status === 3) && (
                        <Tooltip title="Delete" placement="bottom" onClick={() => { setObjone(record); setModalDeleteOpen(true) }} ><DeleteFilled /></Tooltip>
                    )}
                </Space>
            ),
        },
    ];
    useEffect(() => {
        setIsloading(props.isLoading)
        return () => {
            // cleanup
        };
    }, [props]);
    useEffect(() => {
        for (let [key, value] of searchParams.entries()) {
            params[key] = value;
        }
        if (_.isEmpty(params.page)) {
            setSearchParams({ 'page': 1 })
            setCurrent(1)
        } else {
            setCurrent(parseInt(params.page))
        }
        return () => {
            // cleanup
        };
    }, [searchParams]);
    return (
        <>
            <Table
                loading={isLoading}
                tableLayout={'fixed'}
                columns={columns}
                dataSource={props.data.map((e, i) => { return { ...e, 'key': i + 1 } })}
                pagination={{
                    current: current, total: props.data.length, pageSize: 10,
                    onChange: (page, pageSize) => {
                        setCurrent(page);
                        setSearchParams({ 'page': page })
                    },
                }}
            />
            <Component_view
                handleUpdated={() => { props.handleUpdated() }}
                data={objOne}
                modalOpen={modalViewOpen}
                onClose={() => {
                    setModalViewOpen(false);
                }}
            />
            <Component_delete
                handleUpdated={() => { props.handleUpdated() }}
                data={objOne}
                modalOpen={modalDeleteOpen}
                onClose={() => {
                    setModalDeleteOpen(false);
                }}
            />
        </>
    )
}
