import React, { useEffect, useState } from "react";
import {
    Form,
    Input,
    Button,
    Row,
    Col,
    Modal,
    message,
    notification,
    Radio, DatePicker, Space, Select
} from "antd";
import _ from 'lodash';

import createOvertime from "../../api/overtime/createOvertime";
import getAllEmployee from '../../api/employee/getAll';


export default function ModaladdOvertime(prop) {
    const [modalEditOpen, setModalAddOpen] = useState(false);
    const [datasemployee, setDatasemployee] = useState([]);
    const [name, setName] = useState('');
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();
    useEffect(() => {
        setModalAddOpen(prop.modalOpen);
    }, [prop]);
    useEffect(() => {
        getAllEmployee((err, res) => {
            if (err) {
                console.log(err);
            }
            if (!err && res.statusCode === 200) {
                setDatasemployee(res.data);
            } else {
                message.error(res?.message ?? 'fail')
            }
        })
        // effect
        return () => {
            // cleanup
        };
    }, []);
    const openNotificationWithIcon = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    };
    return (
        <>
            <Modal
                title={<div style={{ color: '#81F933' }}>{`Create ${name}`}</div>}
                open={modalEditOpen}
                onCancel={() => {
                    setModalAddOpen(false);
                    form.resetFields();
                    prop.onClose();
                }}
                footer={null}
                closable={true}
                width={500}
            >
                {contextHolder}
                <Form
                    autoComplete="off"
                    layout="vertical"
                    size="middle"
                    form={form}
                    style={{ padding: '10px' }}
                    onFinish={(e) => {
                        var body = e;
                        if (e.status === 3) {
                            body = _.mapKeys(e, function (value, key) {
                                if (key === 'note') {
                                    return 'note_approval'
                                } else {
                                    return key;
                                }
                            });
                        }
                        createOvertime(body,
                            (err, res) => {
                                if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                                    prop.handleUpdated();
                                    setModalAddOpen(false);
                                    prop.onClose();
                                    message.success("Create success");
                                    form.resetFields();
                                } else {
                                    openNotificationWithIcon('error', 400, err ?? 'error not known');
                                }
                            }
                        );
                    }}
                >
                    <Row justify={'start'} gutter={16}>
                        {/* <Space> */}
                        <Col span={12}>
                            <Form.Item
                                label='Appove by'
                                name={`appoved_id`}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Input something!',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select a Appove by"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    options={datasemployee.map((e) => { return { value: e.id, label: e.firstname_th + " " + e.lastname_th } })}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Assigned to'
                                name={`assigned_id`}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Input something!',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select a assigned to"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    options={datasemployee.map((e) => { return { value: e.id, label: e.firstname_th + " " + e.lastname_th } })}
                                />
                            </Form.Item>
                        </Col>
                        {/* </Space> */}
                    </Row>
                    <Row justify={'start'} gutter={16}>
                        {/* <Space> */}
                        <Col span={12} style={{ paddingLeft: '8px', paddingRight: '8px' }}>
                            <Form.Item
                                label='Start Date'
                                name={`startDate`}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Input something!',
                                    },
                                ]}
                            >
                                <DatePicker
                                    showTime={{
                                        format: 'HH:mm',
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='End Date'
                                name={`endDate`}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Input something!',
                                    },
                                ]}
                            >
                                <DatePicker
                                    showTime={{
                                        format: 'HH:mm',
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        {/* </Space> */}
                    </Row>
                    <Row justify={'start'} gutter={16}>
                        {/* <Space> */}
                        <Col span={12}>
                            <Form.Item
                                label='Start Location'
                                name={`startLocaltion`}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Input something!',
                                    },
                                ]}
                            >
                                <Input showCount maxLength={20} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='End Location'
                                name={`endLocaltion`}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Input something!',
                                    },
                                ]}
                            >
                                <Input showCount maxLength={20} />
                            </Form.Item>
                        </Col>
                        {/* </Space> */}
                    </Row>
                    <Row style={{ alignItems: "center" }} >
                        {/* <Space> */}
                        <Col span={12}>
                            <Form.Item
                                label="Status"
                                name={`status`}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Input something!',
                                    },
                                ]}

                            >
                                <Radio.Group >
                                    <Radio value={3}>Appove</Radio>
                                    <Radio value={0}>Disappove</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Total'
                                name={`total`}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Input something!',
                                    },
                                ]}
                            >
                                <Input.TextArea
                                    showCount maxLength={20}
                                    placeholder="Total" 
                                    autoSize={{
                                        minRows: 1,
                                        maxRows: 5,
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label='Note'
                                name={`note`}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Input something!',
                                    },
                                ]}
                            >
                                <Input.TextArea
                                    showCount maxLength={255}
                                    placeholder="Note"
                                    autoSize={{
                                        minRows: 1,
                                        maxRows: 5,
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        {/* </Space> */}
                    </Row>
                    <Row justify="center" style={{ padding: "0 25.57px", gap: "8px" }}>
                        <Button
                            htmlType="button"
                            style={{
                                boxShadow: "unset",
                                textShadow: "unset",
                                borderRadius: "8px",
                                fontSize: "14px",
                                fontWeight: "500",
                            }}
                            onClick={() => {
                                form.resetFields();
                                prop.onClose();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            htmlType="submit"
                            type="primary"
                            style={{
                                backgroundColor: '#81F933',
                                boxShadow: "unset",
                                textShadow: "unset",
                                borderRadius: "8px",
                                fontSize: "14px",
                                fontWeight: "500",
                            }}
                        >
                            Create Overtime
                        </Button>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}
