import React, { useEffect, useState } from 'react'
import { Space, Table, } from 'antd';
import { EditFilled, DeleteFilled } from '@ant-design/icons'
import Component_edit from './modaleditSection';
import Component_delete from './modalDeleteSection';
export default function TableSection(props) {
    const [datas, setDatas] = useState([]);
    const [isLoading, setIsloading] = useState(true);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
    const [objOne, setObjone] = useState({});
    const columns = [
        // {
        //     title: 'Section',
        //     dataIndex: 'id',
        //     key: 'id',
        // },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Manage',
            key: 'manage',
            render: (_, record) => (
                <Space size="middle">
                    <EditFilled onClick={() => { setObjone(record); setModalEditOpen(true) }} />
                    <DeleteFilled onClick={() => { setObjone(record); setModalDeleteOpen(true) }} />
                </Space>
            ),
        },
    ];
    useEffect(() => {
        setDatas(props.data);
        setIsloading(props.isLoading)
        return () => {
            // cleanup
        };
    }, [props]);

    return (
        <>
            <Table loading={isLoading} tableLayout={'fixed'}columns={columns} dataSource={datas} />
            <Component_edit
                handleUpdated={() => { props.handleUpdated() }}
                data={objOne}
                modalOpen={modalEditOpen}
                onClose={() => {
                    setModalEditOpen(false);
                }}
            />
            <Component_delete
                handleUpdated={() => { props.handleUpdated() }}
                data={objOne}
                modalOpen={modalDeleteOpen}
                onClose={() => {
                    setModalDeleteOpen(false);
                }}
            />
        </>
    )
}
