import React, { useEffect, useState } from 'react'
import { Space, Table, Row, Col, Tooltip } from 'antd';
import { EditOutlined, DeleteFilled, UserAddOutlined } from '@ant-design/icons'
import _ from 'lodash';

import ModaleditGroupPermisstion from '../../components/group/editFormGroupPermisstion'
import ModaladdEmployeeGroupPermisstion from '../../components/group/addEmployeeFormGroupPermisstion'
import ModaldeleteGroupPermisstion from '../../components/group/modalDeleteGroupPermisstion'


export default function TableGroupPosition(props) {
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
    const [objOne, setObjone] = useState({});

    useEffect(() => {
        if (!_.isEmpty(props.data.data)) {
            setObjone(props.data.data);
        }
        return () => {
            // cleanup
        };
    }, [props]);

    return (
        <div style={style.box}>
            <div style={{ background: 'rgba(33, 150, 243, 0.75)', padding: '20px 20px 5px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', }}>
                <p style={{ color: '#FFF',marginBottom:8,fontSize: '18px', }}>{objOne.label}</p>
                <p style={{ color: 'rgba(255, 255, 255, 0.60)', fontSize: '16px', marginBottom: 4 }}>Level {objOne.detail?.level}</p>
            </div>
            <div style={{ padding: '16px', }}>
                <Row style={{ background: '#FFF', marginTop: 8, marginBottom: 8 }}>
                    {objOne.detail?.employees?.map((e) => {
                        return (
                            <Col span={24}>
                                <p key={Math.random()} style={{ color: 'var(--444444, #444)', paddingLeft: 10 }}>
                                    : {e.employee.firstname_th} &nbsp;{e.employee.lastname_th}
                                </p>
                            </Col>
                        );
                    })}
                    {_.isEmpty(objOne.detail?.employees) && (<p style={{ color: 'var(--444444, #444)', paddingLeft: 10 }}>:</p>)}
                </Row>

                <h2 style={{ color: 'var(--2196F3, #2196F3)', fontSize: '16px' }}>Sub Group</h2>
                <Row style={{ background: '#FFF', }}>
                    {objOne.detail?.children?.map((e) => {
                        return (
                            <Col span={24}>
                                <p key={Math.random()} style={{ color: 'var(--444444, #444)', paddingLeft: 10 }}>
                                    {e.name}
                                </p>
                            </Col>
                        );
                    })}
                    {_.isEmpty(objOne.detail?.children) && (<p style={{ color: 'var(--444444, #444)', paddingLeft: 10 }}>-</p>)}
                </Row>
                <Row style={{ paddingLeft: 10, }} >
                    <Col span={4}>
                        <Tooltip title="Edit Group" placement="bottom">
                            <EditOutlined onClick={() => { setModalEditOpen(!modalEditOpen) }} style={{ color: '#2196F3', fontSize: '24px', }} />
                        </Tooltip>
                    </Col>
                    <Col span={16}>
                        <Tooltip title="Add Employee" placement="bottom">
                            <UserAddOutlined onClick={() => { setModalAddOpen(!modalAddOpen) }} style={{ color: '#2196F3', fontSize: '24px', }} />
                        </Tooltip>
                    </Col>
                    <Col span={4}>
                        {
                            objOne.detail?.children.length > 0 || objOne.detail?.employees.length > 0
                                ? (<Tooltip title="Delete Group" placement="bottom"><DeleteFilled style={{ color: 'rgba(0, 0, 0, 0.3)', fontSize: '24px', }} /></Tooltip>)
                                : (<Tooltip title="Delete Group" placement="bottom"><DeleteFilled onClick={() => { setModalDeleteOpen(!modalDeleteOpen) }} style={{ color: '#2196F3', fontSize: '24px', }} /></Tooltip>)
                        }
                    </Col>
                </Row>
            </div>
            <ModaladdEmployeeGroupPermisstion
                modalOpen={modalAddOpen}
                data_employee={props.dataEmployee}
                data={objOne}
                name={objOne.label}
                handleUpdated={() => { props.handleUpdated(); }}
                onClose={() => {
                    setModalAddOpen(false);
                }}
            />
            <ModaldeleteGroupPermisstion
                modalOpen={modalDeleteOpen}
                data={objOne}
                name={objOne.label}
                handleUpdated={() => { props.handleUpdated(); }}
                onClose={() => {
                    setModalDeleteOpen(false);
                }}
            />
            <ModaleditGroupPermisstion
                data={objOne} modalOpen={modalEditOpen} name={objOne.label}
                handleUpdated={() => { props.handleUpdated(); }}
                onClose={() => {
                    setModalEditOpen(false);
                }}
            />
        </div>
    )
}
const style = {
    box: {
        position: 'absolute',
        width: 250, zIndex: 9, right: 75, bottom: 0,
        // padding: 20,
        backgroundColor: '#FFF',
        border: '1px soid #2196F3BF',
        borderRadius: '10px',
        boxShadow: '0 0 10px #ccc',
        alignSelf: 'stretch',

    }
}