import React, { useEffect, useState } from 'react'
import { Space, Table, Tag } from 'antd';
import { EditFilled, DeleteFilled, EyeOutlined } from '@ant-design/icons'
import { useSearchParams } from 'react-router-dom'
import moment from 'moment';
import _ from 'lodash'

import Component_edit from './modaleditBroadcast';

import Component_delete from './modalDeleteBroadcast';
import Component_view from './modalViewBroadcast';


export default function TableBroadcast(props) {
    const [datas, setDatas] = useState([]);
    const [current, setCurrent] = useState(1);
    const [isLoading, setIsloading] = useState(true);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
    const [modalViewOpen, setModalViewOpen] = useState(false);
    const [objOne, setObjone] = useState({ Messages: [] });
    const columns = [
        {
            title: 'Date broadcast',
            dataIndex: 'dateBroadcast',
            key: 'dateBroadcast',
            render: (_, record) => {
                return <div>{moment(_).format('DD/MM/YYYY HH:mm')}</div>
            },
            sorter: (a, b) => new Date(a.dateBroadcast) - new Date(b.dateBroadcast),
        },
        {
            title: 'Exmple',
            dataIndex: 'exmple',
            key: 'exmple',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => {
                if (_ === 1) {
                    return <Tag color="#91F363">Ready to send</Tag>
                } else if (_ === 2) {
                    return <Tag color="#D1D1D1">Draft</Tag>
                } else if (_ === 3) {
                    return <Tag color="#6393F3">Sent</Tag>
                }
                else { return <Tag color="#f50">Fail</Tag> }
            },
            sorter: (a, b) => a.status - b.status,
        },
        {
            title: 'Manage',
            key: 'manage',
            render: (_, record) => {
                if (record.status === 2) {
                    return (
                        <Space size="middle">
                            <EyeOutlined onClick={() => { setObjone(record); setModalViewOpen(true) }} />
                            <EditFilled onClick={() => { setObjone(record); setModalEditOpen(true) }} />
                            <DeleteFilled onClick={() => { setObjone(record); setModalDeleteOpen(true) }} />
                        </Space>
                    )
                } else {
                    return (
                        <Space size="middle">
                            <EyeOutlined onClick={() => { setObjone(record); setModalViewOpen(true) }} />
                            <DeleteFilled onClick={() => { setObjone(record); setModalDeleteOpen(true) }} />
                        </Space>
                    )
                }
            },
        },
    ];
    const [searchParams, setSearchParams] = useSearchParams();
    const params = {};
    useEffect(() => {
        setDatas(props.data);
        setIsloading(props.isLoading)
        return () => {
            // cleanup
        };
    }, [props]);
    useEffect(() => {
        for (let [key, value] of searchParams.entries()) {
            params[key] = value;
        }
        if (_.isEmpty(params.page)) {
            setSearchParams({ 'page': 1 })
            setCurrent(1)
        } else {
            setCurrent(parseInt(params.page))
        }
        return () => {
            // cleanup
        };
    }, [searchParams]);
    return (
        <>
            <Table loading={isLoading} columns={columns} dataSource={datas}
                tableLayout={'fixed'}
                pagination={{
                    current: current, total: datas.length, pageSize: 10,
                    onChange: (page, pageSize) => {
                        setCurrent(page);
                        setSearchParams({ 'page': page })
                    },
                }}
            />
            <Component_view
                data={objOne}
                modalOpen={modalViewOpen}
                onClose={() => {
                    setModalViewOpen(false);
                }}
            />
            <Component_edit
                handleUpdated={() => { props.handleUpdated() }}
                data={objOne}
                modalOpen={modalEditOpen}
                onClose={() => {
                    setModalEditOpen(false);
                }}
            />
            <Component_delete
                handleUpdated={() => { props.handleUpdated() }}
                data={objOne}
                modalOpen={modalDeleteOpen}
                onClose={() => {
                    setModalDeleteOpen(false);
                }}
            />
        </>
    )
}
