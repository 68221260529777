import React, { useEffect, useState } from "react";
import { Button, Row, Modal, notification } from "antd";

import disconnectNode from '../../api/grouppermisstion/disconnectNode';
import _ from 'lodash'
export default function ModaldeleteEdge(prop) {

    const [modalOpen, setModalOpen] = useState(false);
    const [body, setBody] = useState({});
    const [api, contextHolder] = notification.useNotification();
    useEffect(() => {
        if (!_.isEmpty(prop.body)) {
            setModalOpen(prop.modalOpen);
            setBody(prop.body);
        }
    }, [prop]);
    const openNotificationWithIcon = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    };
    return (
        <>
            <Modal
                title={<div style={{ color: '#EF3C40' }}>{`Delete Connector`}</div>}
                open={modalOpen}
                footer={null}
                onCancel={() => {
                    prop.onClose();
                }}
                closable={true}
            >
                {contextHolder}
                <div
                    style={{
                        fontSize: "16px",
                        color: "#82828",
                        fontWeight: "normal",
                        textAlign:'center',
                        margin:"10px"
                    }}
                >
                    You’re going to delete this connector between these group. Are you sure?
                </div>
                <Row style={{ justifyContent: "center", padding: "0" }}>
                    <Button
                        htmlType="button"
                        style={{
                            boxShadow: "unset",
                            textShadow: "unset",
                            borderRadius: "8px",
                            fontSize: "14px",
                            margin: "0 0 0px 10px",
                            fontWeight: "500",
                        }}
                        onClick={() => {
                            prop.onClose();
                        }}
                    >
                        No, Deep It.
                    </Button>
                    <Button
                        type="primary"
                        htmlType="button"
                        style={{
                            boxShadow: "unset",
                            textShadow: "unset",
                            borderRadius: "8px",
                            fontSize: "14px",
                            margin: "0 0 0px 10px",
                            fontWeight: "500",
                        }}
                        danger
                        onClick={() => {
                            disconnectNode(body, (err, res) => {
                                if (err) {
                                    console.log(err);
                                    openNotificationWithIcon('error', 400, err ?? 'error not known');
                                }
                                if (!err && res.statusCode === 200) {
                                    prop.handleUpdated();
                                    prop.onClose();
                                } else {
                                    openNotificationWithIcon('error', 400, err ?? 'error not known');
                                }
                            });
                        }}
                    >
                        Yes, Delete!
                    </Button>
                </Row>
            </Modal>
        </>
    );
}
