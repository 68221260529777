import React from 'react';
import { Result, Button } from 'antd';

import {
    useNavigate,
} from "react-router-dom";

export default function Notfound({isAuth}) {
    var navigate = useNavigate();
    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
                <Button type="primary"
                    onClick={
                        () => {
                            if (isAuth) {
                                navigate("/");
                            } else {
                                navigate("/login");
                            }
                        }}>Back Home</Button>
            }
        />
    )
}
