import React, { useEffect, useState } from 'react'
import ProfileEmdetail from '../../components/employee/profile/detail';
import ProfileEmeducation from '../../components/employee/profile/education';
import ProfileEmprofile from '../../components/employee/profile/profile';
import { Row, Col, Button, message, Tag, Spin } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import './p.css'
import _ from 'lodash';
import getEmployee from '../../api/employee/getOne';
export default function Employeeprofile(props) {
    const [isFetch, setIsfetch] = useState(false);
    const [employeeDetail, setEmployeedetail] = useState({
        "employeeId": "-",
        "firstname_th": "-",
        "lastname_th": "-",
        "nickname": '-',
        "positions": '-',
        "bu": '-',
        "education_employee_maps": []
    });
    const navigate = useNavigate();
    const { id } = useParams();
    useEffect(() => {
        if (!isFetch) {
            fetchData(id);
        }

        return () => {
            // cleanup
        };
    }, []);
    const fetchData = (id) => {
        getEmployee(id, (err, res) => {
            if (err) {
                console.log(err);
            }
            if (!err && res.statusCode === 200) {
                let employee = res.data;
                employee["titleId"] = employee.title?.id ?? '-'
                employee["title"] = employee.title?.name ?? '-'
                employee["bu"] = employee.positions?.Sections?.Departments?.business_groups?.name ?? '-'
                employee["department"] = employee.positions?.Sections?.Departments?.name ?? '-'
                employee["section"] = employee.positions?.Sections?.name ?? '-'
                employee["positions"] = employee.positions?.name ?? '-'
                setEmployeedetail(employee);
                setIsfetch(true);
            } else {
                message.error(res?.message ?? 'fail')
            }
        })
    }
    return (
        <Spin spinning={!isFetch}>
            <div style={{ padding: '1.5rem', margin: '20px 15px 15px 20px', background: '#FFF', borderRadius: '1rem' }} className='section-to-print'>
                <Row align='middle' justify='space-between' style={{ marginBottom: '10px' }}>
                    <Col span={10} >
                        <Row style={{ marginBottom: '10px', fontSize: '1.25rem' }}>{employeeDetail.titleId === 1 && 'Mr.'} {employeeDetail.titleId === 2 && 'Mrs.'} {employeeDetail.titleId === 3 && 'Ms.'}{employeeDetail.firstname_en} {employeeDetail.lastname_en} </Row>
                        <Row style={{ marginBottom: '10px', fontSize: '1.25rem' }}>{employeeDetail.title}{employeeDetail.firstname_th} {employeeDetail.lastname_th} [{employeeDetail.nickname}]</Row>
                        <Row align={'middle'}>ID : {employeeDetail.employeeId} &nbsp;
                            {employeeDetail.employee_status?.id === 1 && <Tag color="success">{'Active'}</Tag>}
                            {employeeDetail.employee_status?.id === 2 && <Tag color="error">{'Inactive'}</Tag>}
                            {employeeDetail.employee_status?.id === null && <Tag color="default">{'Unknown'}</Tag>}
                        </Row>
                    </Col>
                    <Col span={14}>
                        <Row style={{ flexWrap: 'nowrap', justifyContent: 'flex-end', gap: '8px', marginBottom: '10px' }}>
                            <Button
                                loading={!isFetch}
                                style={{
                                    borderRadius: '8px',
                                    color: 'var(--ant-primary-color)',
                                    border: '1px solid var(--ant-primary-color)',
                                    // padding: '10px',
                                    //   margin: '20px 10px',
                                    fontWeight: '500',
                                    boxShadow: "unset",
                                    textShadow: "unset",
                                }}
                                onClick={() => navigate(`/resume/export/${employeeDetail.id}`)}
                            >
                                <UploadOutlined /> Export
                            </Button>
                            <Button
                                loading={!isFetch}
                                type={'primary'}
                                style={{
                                    borderRadius: '8px',
                                    fontWeight: '500',
                                    boxShadow: "unset",
                                    textShadow: "unset",
                                }}
                                onClick={() => navigate('/employee')}
                            >
                                Back
                            </Button>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col span={18}><ProfileEmprofile datas={_.pick(employeeDetail, ['address', 'birthday_date', 'gender', 'personal',])} /></Col>
                    <Col span={6}><ProfileEmeducation datas={_.pick(employeeDetail, ['education_employee_maps'])} /></Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <ProfileEmdetail datas={employeeDetail} />
                    </Col>
                </Row>
            </div>
        </Spin>
    )
}
