import React, { useEffect, useState } from 'react'
import { Space, Table, Tooltip, message, Image } from 'antd';
import { NodeCollapseOutlined, NodeExpandOutlined, EyeOutlined } from '@ant-design/icons'
import { useSearchParams } from 'react-router-dom'
import _ from 'lodash'

import Component_Start_Date from './modalEditSWorktime'
import Component_End_Date from './modalEditEWorktime'
import Component_Picture from './modalPicture'

export default function Tableemployee(props) {
    const [isLoading, setIsloading] = useState(true);
    const [modalStartOpen, setModalStartOpen] = useState(false);
    const [modalEndOpen, setModalEndOpen] = useState(false);
    const [modalPictureOpen, setModalPictureOpen] = useState(false);
    const [objOne, setObjone] = useState({});
    const [messageApi, contextHolder] = message.useMessage();
    const [current, setCurrent] = useState(1);
    const [searchParams, setSearchParams] = useSearchParams();
    const params = {};
    const columns = [
        {
            title: 'No.',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Employee',
            dataIndex: 'employeeId',
            key: 'employeeId',
            sorter: (a, b) => a.employeeId - b.employeeId
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Business Unit',
            dataIndex: 'business_groups',
            key: 'business_groups',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            sorter: (a, b) => new Date(a.sortStartDate) - new Date(b.sortStartDate),
        },
        {
            title: 'Check In',
            dataIndex: 'starttime',
            key: 'starttime',
            render(_, record) {
                return {
                    props: {
                        style: { background: record.manually ? "rgba(33, 150, 243, 0.2)" : "" }
                    },
                    children: <div>{_}</div>
                };
            }
        },
        // {
        //     title: 'End Date',
        //     dataIndex: 'enddate',
        //     key: 'enddate',
        //     sorter: (a, b) => new Date(a.sortEndDate) - new Date(b.sortEndDate),
        // },
        {
            title: 'Location',
            dataIndex: 'location_start',
            key: 'location_start',
        },
        {
            title: 'Check In Pic',
            dataIndex: 'starttime_pic',
            key: 'starttime_pic',
            render(_, record) {
                if (_ === '-') {
                    return _
                } else {
                    return <div style={{ textAlign: 'center' }}>
                        <EyeOutlined style={{ fontSize: '18px', color: '#2feba3' }}
                            onClick={() => { setObjone({ picURL: _, ...record }); setModalPictureOpen(true) }} />
                    </div>
                }
            },
        },
        {
            title: 'Check Out',
            dataIndex: 'endtime',
            key: 'endtime',
            render(_, record) {
                return {
                    props: {
                        style: { background: record.manually_end ? "rgba(33, 150, 243, 0.2)" : "" }
                    },
                    children: <div>{_}</div>
                };
            }
        },
        {
            title: 'Location',
            dataIndex: 'location_end',
            key: 'location_end',
        },
        {
            title: 'Check Out Pic',
            dataIndex: 'endtime_pic',
            key: 'endtime_pic',
            render(_, record) {
                if (_ === '-') {
                    return _
                } else {
                    return <div style={{ textAlign: 'center' }}>
                        <EyeOutlined style={{ fontSize: '18px', color: '#eb2f96' }}
                            onClick={() => { setObjone({ picURL: _, ...record }); setModalPictureOpen(true) }} />
                    </div>
                }
            },

        },
        {
            title: 'Summary',
            dataIndex: 'sumtotal',
            key: 'sumtotal',
            render(_, record) {
                if (_ === '-') {
                    return _
                } else {
                    return `${_.split(':')[0]} hr.${_.split(':')[1]} min`;
                }
            },
        },
        {
            title: 'Manage',
            key: 'manage',
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip title="Edit Check In">
                        <NodeCollapseOutlined style={{ fontSize: '18px', color: '#2feba3' }} onClick={() => { setObjone(record); setModalStartOpen(true) }} />
                    </Tooltip>
                    <Tooltip title="Edit Check Out">
                        <NodeExpandOutlined style={{ fontSize: '18px', color: '#eb2f96' }}
                            onClick={() => {
                                if (record.idStartDate === '') {
                                    messageApi.open({
                                        type: 'error',
                                        content: 'กรุณาเพิ่มเวลาเข้างานก่อนจะเพิ่มเวลาออกงาน',
                                    });
                                    return;
                                }
                                setObjone(record); setModalEndOpen(true)
                            }} />
                    </Tooltip>
                </Space>
            ),
        },
    ];
    useEffect(() => {
        setIsloading(props.isLoading)
        return () => {
            // cleanup
        };
    }, [props]);
    useEffect(() => {
        for (let [key, value] of searchParams.entries()) {
            params[key] = value;
        }
        if (_.isEmpty(params.page)) {
            setSearchParams({ 'page': 1 })
            setCurrent(1)
        } else {
            setCurrent(parseInt(params.page))
        }
        return () => {
            // cleanup
        };
    }, [searchParams]);
    return (
        <>
            {contextHolder}
            <Table loading={isLoading} columns={columns}
                tableLayout={'fixed'}
                dataSource={props.data.map((e, i) => { return { ...e, 'key': i + 1 } })}
                pagination={{
                    current: current, total: props.data.length, pageSize: 10,
                    onChange: (page, pageSize) => {
                        setCurrent(page);
                        setSearchParams({ 'page': page })
                    },
                }}
            />
            <Component_Start_Date
                handleUpdated={() => { props.handleUpdated() }}
                data={objOne}
                modalOpen={modalStartOpen}
                onClose={() => {
                    setModalStartOpen(false);
                }}
            />
            <Component_End_Date
                handleUpdated={() => { props.handleUpdated() }}
                data={objOne}
                modalOpen={modalEndOpen}
                onClose={() => {
                    setModalEndOpen(false);
                }}
            />
            <Component_Picture
                data={objOne}
                modalOpen={modalPictureOpen}
                onClose={() => {
                    setModalPictureOpen(false);
                }}
            />
        </>
    )
}