import React, { memo } from 'react';
import { Divider, Row, Col, Space, Tag } from 'antd';
import { Handle, Position } from 'reactflow';
import './rootnode.css'

export default memo(({ data, isConnectable }) => {
    return (
        <div style={styles.card} className='cardchar'>
            <Handle type="target" position={Position.Top} />
            <Row justify={'start'} >
                <Col span={24}>
                    <div style={{ padding: '10 0' }}>{data.label} </div>
                </Col>
            </Row>
            <Divider style={{ margin: '10px' }} />
            <Row justify={'center'} align={'middle'} style={{ padding: '0px 16px' }}>
                <Col span={24}>
                    <Space >
                        <Tag color="success" style={{ margin: 0 }}>Level {data.detail.level}</Tag>
                        <Tag color="processing" style={{ margin: 0 }}>{data.detail.employees.length} person</Tag>
                    </Space>
                </Col>
            </Row>
            <Handle type="source" position={Position.Bottom}   isConnectable={data.isConnectable} />
        </div>
    )
});

const styles = {
    card: {
        display: 'inline-flex',
        padding: '8px 0px',
        flexDirection: 'column',
        alignItems: 'center',

    }
}