import React, { useEffect, useState } from 'react'
import TablePosition from '../../../components/positions/tablePosition';
import AddFormPosition from '../../../components/positions/addFormPosition';
import { Row, Col, Button, Input, message } from 'antd'
import { SearchOutlined, PlusOutlined, } from '@ant-design/icons'
import getAllPosition from '../../../api/positions/getAll';
export default function Position(props) {
    const [isFetch, setIsfetch] = useState(false);
    const [addButton, setAddbutton] = useState(false);
    const [isLoading, setIsloading] = useState(true);
    const [datas, setDatas] = useState([]);
    const [datasFilter, setDatasFilter] = useState([]);
    useEffect(() => {
        if (!isFetch) {
            fetch_data()
        }
        return () => {
            // cleanup
        };
    }, []);
    const fetch_data = () => {
        getAllPosition((err, res) => {
            if (err) {
                console.log(err);
            }
            if (!err && res.statusCode === 200) {
                setDatas(res.data);
                setDatasFilter(res.data);
                setIsfetch(true);
                setIsloading(false);
            } else {
                message.error(res?.message ?? 'fail')
            }
        })
    }
    return (
        <div style={{ padding: '20px', margin: '20px 15px 15px 20px', background: '#FFF', borderRadius: '16px' }}>
            <Row align='middle' justify='space-between' style={{ marginBottom: '10px' }}>
                <Col span={10} style={{ color: "var(--ant-primary-color)" }}>
                    {addButton ? 'Add Position ' : ''}
                </Col>
                <Col span={14}>
                    <Row style={{ flexWrap: 'nowrap', justifyContent: 'flex-end', gap: '8px' }}>
                        {!addButton && (
                            <Input
                                suffix={
                                    <SearchOutlined style={{ color: 'rgba(79, 79, 79, 0.5)' }} />
                                }
                                className='ant-inputsearch search-border'
                                placeholder={`Search....`}
                                style={{
                                    margin: '0px 0px 0px 10px',
                                    borderRadius: '8px',
                                    // border: '1px solid var(--ant-primary-color)',
                                    maxWidth: '200px'
                                }}
                                onChange={e => {
                                    const currValue = e.target.value
                                    const filteredData = datasFilter.filter(entry =>
                                        Object.keys(entry).filter((k) => k).some(s => {
                                            return String(entry[s])
                                                .toLowerCase()
                                                .includes(currValue.toLowerCase())
                                        })
                                    )
                                    setDatas(filteredData)
                                }}
                            />
                        )}
                        {/* {!addButton && (
                            <Button
                                loading={!isFetch}
                                style={{
                                    borderRadius: '8px',
                                    color: 'var(--ant-primary-color)',
                                    border: '1px solid var(--ant-primary-color)',
                                    // padding: '10px',
                                    //   margin: '20px 10px',
                                    fontWeight: '500',
                                    boxShadow: "unset",
                                    textShadow: "unset",
                                }}
                                onClick={() => setAddbutton(!addButton)}
                            >
                                <UploadOutlined /> Export
                            </Button>
                        )} */}
                        <Button
                            loading={!isFetch}
                            type={!addButton ? 'primary' : ''}
                            style={{
                                borderRadius: '8px',
                                // color: '#4F4F4F',
                                // padding: '10px',
                                //   margin: '20px 10px',
                                fontWeight: '500',
                                boxShadow: "unset",
                                textShadow: "unset",
                            }}
                            onClick={() => { setAddbutton(!addButton); setDatas(datasFilter) }}
                        >
                            {!addButton && <PlusOutlined />}
                            {!addButton ? 'Add Position' : 'Cancel'}
                        </Button>
                    </Row>
                </Col>
            </Row>
            {addButton && (
                <AddFormPosition onClose={() => { setAddbutton(!addButton); }}
                    handleUpdated={() => {
                        setIsloading(true);
                        setTimeout(() => {
                            fetch_data();
                        }, 1000);
                    }}
                />
            )}
            {isFetch && (
                <TablePosition data={datas} isLoading={isLoading}
                    handleUpdated={() => {
                        setIsloading(true);
                        setTimeout(() => {
                            fetch_data();
                        }, 1000);
                    }}
                />
            )}
        </div>
    )
}
