import React, { useEffect, useState } from 'react'
import { Row, Col, theme, Space, Form, Button, Select, DatePicker, notification } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import handlePrint from './handlePrint';

import getReport from '../../../api/overtime/getReport';

import { useSelector } from 'react-redux'
import { getBusinessUnit } from "../../../slice/businessunitSlice";

const { useToken } = theme;
export default function ButtonWeekreport(props) {
    const dataBU = useSelector(getBusinessUnit);
    const [api, contextHolder] = notification.useNotification();
    const { token } = useToken();
    const [form] = Form.useForm();
    const contentStyle = {
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
    };
    const openNotificationWithIcon = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    };
    return (
        <div style={contentStyle}>
            {contextHolder}
            <Form form={form} name="advanced_search" onFinish={(e) => {
                getReport(dayjs(e.date).format('YYYY-MM-DD'), e.businessunit, (err, res) => {
                    if (err) {
                        openNotificationWithIcon('error', 400, err ?? 'error not known');
                    }
                    if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                        handlePrint(res.data);
                        form.resetFields();
                    }
                    if (res.statusCode >= 400) {
                        openNotificationWithIcon('error', res.statusCode, res.message);
                    }
                })
            }}>
                <Row>
                    <Col span={24} style={{ padding: 8, }}>
                        <Form.Item
                            name={`businessunit`}
                            rules={[
                                {
                                    required: true,
                                    message: 'Input something!',
                                },
                            ]}
                            style={{ margin: 0 }}
                        >
                            <Select
                                style={{ width: '100%' }}
                                showSearch
                                placeholder="Select Business Unit"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={dataBU.map(b => {
                                    return {
                                        value: b.id,
                                        label: b.name,
                                    }
                                }) || []}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ padding: 8, }}>
                        <Form.Item
                            name={`date`}
                            rules={[
                                {
                                    required: true,
                                    message: 'Input something!',
                                },
                            ]}
                            style={{ margin: 0 }}
                        >
                            <DatePicker style={{ width: '100%' }}
                                placeholder="DD/MM/YYYY" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <Space
                        style={{
                            padding: 8,
                        }}
                    >
                        <Button type="primary" htmlType="submit" ><ExportOutlined /> Export</Button>
                    </Space>
                </Row>
            </Form>
        </div>
    )
}